import React from 'react';

import { useFormik } from 'formik';

import { useDispatch } from 'react-redux';

import { patchBankAccount } from 'store/bankAccounts/action';

import { IFranchises } from 'store/franchises/type';
import { bankAccountForm } from './form';

import { ContentComponent } from 'components/Content';
import { InputComponent } from 'components/Input';
import { GridLayoutComponent } from 'components/Layout/Grid';
import { ButtonComponent } from 'components/Button';

interface IBankAccountForm {
  franchise?: IFranchises;
  loading: boolean;
}

export const BankAccountForm: React.FC<IBankAccountForm> = ({
  loading,
  franchise,
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: bankAccountForm(franchise),
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(
        patchBankAccount({
          id: franchise?.contract?.id,
          bank_account: {
            bank_account: values.bank_account,
            bank_branch: values.bank_branch,
            bank_name: values.bank_name,
            bank_number: Number(values.bank_number),
          },
        }),
      );
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ContentComponent filter={loading}>
        <GridLayoutComponent number={2}>
          <InputComponent
            type="number"
            name="bank_number"
            label="Número do Banco"
            onChange={formik.handleChange}
            value={formik.values.bank_number}
          />
          <InputComponent
            type="text"
            name="bank_name"
            label="Nome do Banco"
            onChange={formik.handleChange}
            value={formik.values.bank_name}
          />
          <InputComponent
            type="text"
            name="bank_account"
            label="Conta"
            onChange={formik.handleChange}
            value={formik.values.bank_account}
          />
          <InputComponent
            type="text"
            name="bank_branch"
            label="Agência"
            onChange={formik.handleChange}
            value={formik.values.bank_branch}
          />
        </GridLayoutComponent>

        <ButtonComponent style={{ marginTop: 22 }} type="submit">
          Atualizar
        </ButtonComponent>
      </ContentComponent>
    </form>
  );
};
