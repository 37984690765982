import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchRolesRoutes, rolesState } from 'store/roles/action';

import { LayoutComponent } from 'components/Layout';
import { BreadCrumbsComponent } from 'components/Breadcrumbs';
import { ButtonComponent } from 'components/Button';
import { CreateOrEdit } from './include/CreateOrEdit';

const CreatePermission: React.FC = () => {
  const dispatch = useDispatch();
  const { routes, loadingSec } = useSelector(rolesState);

  useEffect(() => {
    dispatch(fetchRolesRoutes());
  }, []); //eslint-disable-line

  return (
    <LayoutComponent loading={loadingSec}>
      <BreadCrumbsComponent title="Create">
        <ButtonComponent color="red">Sair</ButtonComponent>
      </BreadCrumbsComponent>

      <CreateOrEdit roles={routes} type="create" />
    </LayoutComponent>
  );
};

export default CreatePermission;
