import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IGrid {
  number: number;
}

export const Grid = styled.div<IGrid>`
  display: grid;
  gap: 22px;

  h3 {
    font-size: 24px;
  }

  && + div {
    margin-top: 22px;
  }

  ${props =>
    props.number === 1 &&
    css`
      grid-template-columns: 1fr;
    `}

  ${props =>
    props.number === 2 &&
    css`
      grid-template-columns: 1fr 1fr;
    `}

  ${props =>
    props.number === 3 &&
    css`
      grid-template-columns: 1fr 1fr 1fr;
    `}

    ${props =>
    props.number === 4 &&
    css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `}

  .grid-emailcode {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 22px;
  }
`;
