import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IContractStatus {
  type: string;
}

export const ContentChart = styled.div`
  display: flex;
  width: 100%;
  height: 420px;
  background: #fff;
  border-radius: 6px;
  padding: 14px;
  margin-top: 22px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
`;

export const ContractStatus = styled.div<IContractStatus>`
  display: block;
  width: auto;
  text-align: center;
  border-radius: 4px;
  padding: 4px 12px;
  color: #fff;

  ${props =>
    props.type === 'new' &&
    css`
      background: #5e8e42;
    `}

  ${props =>
    props.type === 'reoponing' &&
    css`
      background: #b0a81f;
    `}

  ${props =>
    props.type === 'transfer' &&
    css`
      background: #256dbe;
    `}

  ${props =>
    props.type === 'closing' &&
    css`
      background: #e43766;
    `}
`;

export const SearchYear = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 12px;
`;
