import { createSlice } from '@reduxjs/toolkit';
import { IRolesTools, IRoles, IPermissions } from './type';

const store = createSlice({
  name: 'roles',
  initialState: {
    data: [] as IRoles[],
    show: {} as IRolesTools,
    tools: [] as IRolesTools[],
    routes: [] as IPermissions[],
    loading: false,
    loadingSec: false,
  },
  reducers: {
    addRoles: (state, action) => {
      state.data = action.payload;
    },
    addRole: (state, action) => {
      state.show = action.payload;
    },
    addRoutes: (state, action) => {
      state.routes = action.payload;
    },
    addRolesTools: (state, action) => {
      state.tools = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
    loadingSec: (state, action) => {
      state.loadingSec = action.payload;
    },
  },
});

export const {
  addRoles,
  addRole,
  addRoutes,
  addRolesTools,
  loading,
  loadingSec,
} = store.actions;
export default store.reducer;
