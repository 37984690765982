import React from 'react';

import { SelectComponent } from 'components/Select';

import { Container } from './styles';

const data = [
  {
    name: '10',
    value: '10',
  },
  {
    name: '15',
    value: '15',
  },
  {
    name: '20',
    value: '20',
  },
  {
    name: '25',
    value: '25',
  },
  {
    name: '30',
    value: '30',
  },
  {
    name: '60',
    value: '60',
  },
];

interface ISearch {
  limit?: string;
  handleLimit?: any;
}

export const SearchComponent: React.FC<ISearch> = ({ limit, handleLimit }) => {
  return (
    <Container data-testid="container">
      <div className="per_page">
        {handleLimit && (
          <SelectComponent
            title="Quantidade por página"
            name="per_page"
            value={limit}
            handleChange={handleLimit}
            data={data}
          />
        )}
      </div>
    </Container>
  );
};
