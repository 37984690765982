import styled from '@emotion/styled';

export const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 12px;

  .block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    padding: 16px 12px;

    .total {
      font-size: 28px;
      font-weight: bold;
    }

    .details {
      display: flex;
      align-items: center;

      .name {
        display: flex;
        flex-direction: column;
        margin-left: 6px;

        span {
          font-size: 16px;
        }

        small {
          font-size: 12px;
          color: #eee;
        }
      }
    }
  }

  .green {
    background: #5e8e42;
    color: #fff;
  }

  .green_secondary {
    background: #7eb95b;
    color: #fff;
  }

  .yellow {
    background: #b0a81f;
    color: #fff;
  }

  .blue {
    background: #256dbe;
    color: #fff;
  }

  .red {
    background: #e43766;
    color: #fff;
  }
`;
