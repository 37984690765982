import { toast } from 'react-toastify';
import { AppDispatch, AppThunk, RootState } from 'store';
import { formatDatePtBr } from 'utils/mask';

import { showFranchises } from '../franchises/action';

import {
  addContracts,
  addContractTypes,
  loading,
  loadingPatch,
  loadingTransfer,
  modalType,
  modalNewContract,
  modalTransferContract,
} from '.';

import { paramsFetch, IParams } from '../../utils/params';
import {
  IPatchContractGateway,
  IPatchContractTransfer,
  IPatchContractType,
  IPostContractNew,
} from './type';
import { Errors } from 'utils/errors';
import { refreshToken } from 'services/refreshToken';

export function fetchContracts(data?: IParams): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));

      const params = paramsFetch(data);
      const { clientApi } = await refreshToken();
      const response = await clientApi.get('contracts', { params });

      dispatch(addContracts(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function fetchContractTypes(): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      const { clientApi } = await refreshToken();
      const response = await clientApi.get('contract-types');

      dispatch(addContractTypes(response.data));
    } catch (err) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    }
  };
}

export function patchContractType({
  id,
  contractTypeId,
}: IPatchContractType): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loadingPatch(true));
      const { clientApi } = await refreshToken();
      await clientApi.patch(`contracts/${id}/type`, { contractTypeId });
      dispatch(modalType(false));
      toast.success('Status do contrato atualizado com sucesso.');
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingPatch(false));
    }
  };
}

export function patchContractGateway(
  id: number,
  data: IPatchContractGateway,
): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loadingPatch(true));
      const { clientApi } = await refreshToken();
      await clientApi.patch(`contracts/gateway/${id}`, data);
      toast.success('Atualizado com sucesso.');
    } catch (err) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingPatch(false));
    }
  };
}

export function postContractNew(
  data: IPostContractNew,
  franchiseEmail: string,
): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loadingPatch(true));
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      await clientApi.post(`contracts/new`, {
        ...data,
        birthdate: formatDatePtBr(data.birthdate),
        date_opening: formatDatePtBr(data.date_opening),
        franchiseEmail,
      });
      toast.success('Atualizado com sucesso.');

      dispatch(showFranchises(franchiseEmail));
      dispatch(modalNewContract(false));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
      dispatch(loadingPatch(false));
    }
  };
}

export function patchContractTransfer(data: IPatchContractTransfer): AppThunk {
  return async function (dispatch: AppDispatch) {
    const {
      others: { franchiseEmail, contractFiscalNumber },
      ...rest
    } = data;
    try {
      dispatch(loadingTransfer(true));
      const { clientApi } = await refreshToken();
      await clientApi.patch(`contracts/transfer/${contractFiscalNumber}`, {
        ...rest,
        birthdate: formatDatePtBr(data.birthdate),
      } as IPatchContractTransfer);

      dispatch(showFranchises(franchiseEmail));
      dispatch(modalTransferContract(false));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingTransfer(false));
    }
  };
}

export function setModalContractType(open: boolean): AppThunk {
  return function (dispatch: AppDispatch) {
    dispatch(modalType(open));
  };
}

export function setModalNewContract(open: boolean): AppThunk {
  return function (dispatch: AppDispatch) {
    dispatch(modalNewContract(open));
  };
}

export function setModalTransferContract(open: boolean): AppThunk {
  return function (dispatch: AppDispatch) {
    dispatch(modalTransferContract(open));
  };
}

export const contractsState = (state: RootState) => state.contracts;
