import styled from '@emotion/styled';

interface IGridButton {
  type: boolean;
}

export const GridButton = styled.div<IGridButton>`
  display: ${props => (props.type ? 'flex' : 'block')};
  width: 100%;
  margin-top: 12px;

  button + button {
    margin-left: 12px;
  }
`;
