import styled from '@emotion/styled';

interface IGridButton {
  type: boolean;
}

export const GridPermission = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;

  li {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 1px solid #999;

    strong {
      font-size: 16px;
    }

    div {
      display: flex;
    }

    .role-fd {
      display: flex;
      flex-direction: row;
      margin-top: 12px;

      input[type='checkbox'] {
        transform: scale(1.5);
        margin-right: 4px;
      }

      span {
        margin-left: 2px;
        margin-right: 16px;
      }
    }
  }
`;

export const GridButton = styled.div<IGridButton>`
  display: ${props => (props.type ? 'flex' : 'block')};
  width: 100%;
  margin-top: 12px;

  button + button {
    margin-left: 12px;
  }
`;
