import React from 'react';

import { Grid } from './styles';

interface IGrid {
  number: number;
}

export const GridLayoutComponent: React.FC<IGrid> = ({ number, children }) => {
  return <Grid number={number}>{children}</Grid>;
};
