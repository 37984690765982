import React from 'react';
import { Link } from 'react-router-dom';

import {
  MdBuild,
  MdBusiness,
  MdDashboard,
  MdKeyboardArrowRight,
  MdPerson,
  MdSettings,
  MdFreeBreakfast,
} from 'react-icons/md';
import { nextPermission } from 'role-permissions-react';

import { useAuth } from 'hooks/auth';

import { NavigationStyled, Menu } from './styles';
import { AiOutlineFileText, AiOutlineLogout } from 'react-icons/ai';

export const Navigation: React.FC = () => {
  const { signOut, me } = useAuth();
  const { pathname } = window.location;

  return (
    <NavigationStyled>
      <div className="navigation">
        <Menu>
          <li>
            <Link to="/" className={pathname === '/' ? 'active' : 'nd'}>
              <span>
                <MdDashboard /> Dashboard
              </span>{' '}
              <MdKeyboardArrowRight size={12} />
            </Link>
          </li>
          {nextPermission({
            roles: me?.roles,
            pathname: 'franchises',
          }) && (
            <li>
              <Link
                to="/franchises"
                className={pathname.includes('franchises') ? 'active' : 'nd'}
              >
                <span>
                  <MdBusiness /> Franqueados
                </span>{' '}
                <MdKeyboardArrowRight size={12} />
              </Link>
            </li>
          )}
          {nextPermission({
            roles: me?.roles,
            pathname: 'contracts',
          }) && (
            <li>
              <Link
                to="/contracts"
                className={pathname.includes('contracts') ? 'active' : 'nd'}
              >
                <span>
                  <AiOutlineFileText /> Contratos
                </span>{' '}
                <MdKeyboardArrowRight size={12} />
              </Link>
            </li>
          )}
          {nextPermission({
            roles: me?.roles,
            pathname: 'owners',
          }) && (
            <li>
              <Link
                to="/owners"
                className={pathname.includes('owners') ? 'active' : 'nd'}
              >
                <span>
                  <MdFreeBreakfast /> Proprietários
                </span>{' '}
                <MdKeyboardArrowRight size={12} />
              </Link>
            </li>
          )}
          {nextPermission({
            roles: me?.roles,
            pathname: 'users',
          }) && (
            <li>
              <Link
                to="/users"
                className={pathname.includes('users') ? 'active' : 'nd'}
              >
                <span>
                  <MdPerson /> Usuários
                </span>{' '}
                <MdKeyboardArrowRight size={12} />
              </Link>
            </li>
          )}
          {nextPermission({
            roles: me?.roles,
            pathname: 'permissions',
          }) && (
            <li>
              <Link
                to="/permissions"
                className={pathname.includes('permissions') ? 'active' : 'nd'}
              >
                <span>
                  <MdBuild /> Permissões
                </span>{' '}
                <MdKeyboardArrowRight size={12} />
              </Link>
            </li>
          )}
          {nextPermission({
            roles: me?.roles,
            pathname: 'settings',
          }) && (
            <li>
              <Link
                to="/settings"
                className={pathname.includes('settings') ? 'active' : 'nd'}
              >
                <span>
                  <MdSettings /> Configurações
                </span>{' '}
                <MdKeyboardArrowRight size={12} />
              </Link>
            </li>
          )}
        </Menu>

        <button type="button" className="btn-logout" onClick={() => signOut()}>
          <AiOutlineLogout /> Deixar o sistema
        </button>
      </div>
    </NavigationStyled>
  );
};
