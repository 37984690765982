import styled from '@emotion/styled';

export const Navigation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  margin-top: 14px;
  background: #fff;

  .active {
    background: #376444;
    color: #fff;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303030;
    background: transparent;
    padding-left: 12px;
    padding-right: 12px;
    height: 38px;
    border: 0;

    &:disabled {
      opacity: 0.5;
    }

    svg {
      margin-right: 4px;
    }

    &:hover {
      background: #376444;
      color: #fff;
    }

    & + button {
      border-left: 1px solid #d2d2d2;
    }
  }
`;
