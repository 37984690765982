import styled from '@emotion/styled';
import { shade } from 'polished';

export const NavigationStyled = styled.div`
  display: flex;
  position: relative;
  width: 280px;
  height: 100%;
  z-index: 98;

  .navigation {
    display: flex;
    position: fixed;
    width: 240px;
    height: 100%;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.6);
    z-index: 98;
    padding: 12px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    padding-top: 44px;

    .btn-logout {
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 12px;
      width: 216px;
      height: 38px;
      background: linear-gradient(-90deg, #4f6343, #364c29);
      color: #fff;
      border-radius: 6px;

      svg {
        margin-right: 4px;
      }

      &:hover {
        background: ${shade(0.2, '#364c29')};
      }
    }
  }
`;

export const Menu = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 100%;

  li {
    display: flex;
    width: 100%;

    & + li {
      margin-top: 8px;
    }

    .active {
      background: linear-gradient(-90deg, #557941, #5e8e42);
      color: #fff;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #1c1c1c;
      padding: 10px 8px;
      border-radius: 6px;

      span {
        display: flex;
        align-items: center;

        svg {
          margin-right: 4px;
        }
      }

      &:hover {
        background: linear-gradient(-90deg, #557941, #5e8e42);
        color: #fff;
      }
    }
  }
`;
