import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { queryParams, queryURI } from 'queryparamsuri';
import { MdModeEdit } from 'react-icons/md';

import { useDispatch, useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material/Select';

import { fetchContracts, contractsState } from 'store/contracts/action';

import { ContentComponent } from 'components/Content';
import { LayoutComponent } from 'components/Layout';
import { BreadCrumbsComponent } from 'components/Breadcrumbs';
import { PaginationComponent } from 'components/Pagination';
import { SearchComponent } from 'components/Layout/Search';
import { IHandleSearch, Popover } from 'components/Popover';
import { ButtonComponent } from 'components/Button';
import { convertEmail } from 'utils/convertEmail';

const Contracts: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: contracts, paginate, loading } = useSelector(contractsState);

  const { searchURI, page, limit, query } = queryParams({
    params: ['cnpj', 'company_name'],
  });

  useEffect(() => {
    dispatch(fetchContracts({ page, limit, ...query }));
  }, [searchURI]); // eslint-disable-line

  const handlePage = (value: any) => {
    const params = queryURI({
      name: 'limit',
      value,
    });

    history.push(params);
  };

  const handleLimit = (event: SelectChangeEvent) => {
    const params = queryURI({
      name: 'limit',
      value: event.target.value,
    });

    history.push(params);
  };

  const handleSearch = (values: IHandleSearch) => {
    const params = queryURI({
      name: values.name,
      value: values.search,
    });

    history.push(params);
  };

  return (
    <LayoutComponent>
      <BreadCrumbsComponent />

      <SearchComponent
        limit={String(paginate.per_page)}
        handleLimit={handleLimit}
      />

      <ContentComponent filter={loading}>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <td>#</td>
              <td>Data de Abertura</td>
              <td>
                Cnpj{' '}
                <Popover
                  handleSearch={handleSearch}
                  researched={query.cnpj}
                  name="cnpj"
                />
              </td>
              <td>
                Razão Social{' '}
                <Popover
                  handleSearch={handleSearch}
                  researched={query.company_name}
                  name="company_name"
                />
              </td>
              <td>Ação</td>
            </tr>
          </thead>
          <tbody>
            {contracts.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.date_opening}</td>
                <td>{item.cnpj}</td>
                <td>{item.company_name}</td>
                <td>
                  <ButtonComponent
                    size="small"
                    paddingVertical="small"
                    onClick={() =>
                      history.push(
                        `/franchises/edit/${convertEmail(
                          item.franchise.email,
                        )}`,
                      )
                    }
                  >
                    <MdModeEdit /> Editar
                  </ButtonComponent>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContentComponent>

      <PaginationComponent
        paginate={paginate}
        defaultPage={Number(page)}
        handlePage={handlePage}
      />
    </LayoutComponent>
  );
};

export default Contracts;
