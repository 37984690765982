import React from 'react';

import { t } from 'ab18n';
import { useFormik } from 'formik';

import { ownerAndContractForm } from './form';

import { ContentComponent } from 'components/Content';
import { ButtonComponent } from 'components/Button';
import { GridLayoutComponent } from 'components/Layout/Grid';
import { InputComponent } from 'components/Input';
import { GridOwnerOrContract, GridStatus } from 'pages/Franchises/styles';
import { IFranchises } from 'store/franchises/type';
import { DateInputComponent } from 'components/Input/date';

interface IOwnerAndContract {
  loading: boolean;
  franchise?: IFranchises;
  modalNewContract: any;
  modalTransferContract: any;
}

export const OwnerAndContractForm: React.FC<IOwnerAndContract> = ({
  loading,
  franchise,
  modalNewContract,
  modalTransferContract,
}) => {
  const formik = useFormik({
    initialValues: ownerAndContractForm(franchise),
    enableReinitialize: true,
    onSubmit: values => console.log(values),
  });
  const verifyIfExistsContractTypes = !!formik.values.contractType.description;

  return (
    <ContentComponent
      filter={loading}
      content={
        <>
          <ButtonComponent
            type="button"
            size="small"
            onClick={modalNewContract}
          >
            Adicionar um Novo Contrato
          </ButtonComponent>
          {franchise?.contract && Object.keys(franchise.contract).length > 0 && (
            <>
              <ButtonComponent
                type="button"
                size="small"
                color="gray"
                onClick={modalTransferContract}
              >
                Transferir Contrato
              </ButtonComponent>
            </>
          )}
        </>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <GridOwnerOrContract>
          <p>Responsável: </p>
          <GridLayoutComponent number={2}>
            <InputComponent
              label="Nome"
              name="owner.name"
              value={formik.values.owner.name}
              onChange={formik.handleChange}
              disabled
            />
            <InputComponent
              label="Email"
              name="contract.owner.email"
              value={formik.values.owner.email}
              onChange={formik.handleChange}
              disabled
            />
          </GridLayoutComponent>
          <GridLayoutComponent number={3}>
            <InputComponent
              label="CPF"
              name="owner.fiscal_number"
              value={formik.values.owner.fiscal_number}
              onChange={formik.handleChange}
              mask="999.999.999-99"
              disabled
            />
            <InputComponent
              label="Telefone"
              name="owner.phone"
              value={formik.values.owner.phone}
              onChange={formik.handleChange}
              mask={
                formik.values.owner.phone.length > 10
                  ? '(99) 99999-9999'
                  : '(99) 9999-9999'
              }
              disabled
            />
            <DateInputComponent
              label="Nascimento"
              name="owner.birthdate"
              value={formik.values.owner.birthdate}
              handleDateChange={formik.handleChange}
              disabled
            />
          </GridLayoutComponent>
        </GridOwnerOrContract>

        <GridOwnerOrContract style={{ marginTop: 12 }}>
          <p>Contrato:</p>
          <GridLayoutComponent number={4}>
            <DateInputComponent
              label="Data de Abertura"
              name="date_opening"
              value={formik.values.date_opening}
              handleDateChange={formik.handleChange}
              disabled
            />
            <InputComponent
              label="CNPJ"
              name="cnpj"
              value={formik.values.cnpj}
              onChange={formik.handleChange}
              disabled
              mask="99.999.999/9999-99"
            />
            <InputComponent
              label="Razão Social"
              name="company_name"
              value={formik.values.company_name}
              onChange={formik.handleChange}
              disabled
            />
            <GridStatus status={formik.values.contractType.description || 'nd'}>
              Tipo de Contrato:{' '}
              {verifyIfExistsContractTypes &&
                t(
                  `dashboard.contractType.${formik.values.contractType.description}`,
                )}
            </GridStatus>
          </GridLayoutComponent>
        </GridOwnerOrContract>
        <ButtonComponent disabled style={{ marginTop: 22 }} type="button">
          Atualizar
        </ButtonComponent>
      </form>
    </ContentComponent>
  );
};
