import React from 'react';

import { t } from 'ab18n';
import { useLocation, useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import { Container } from './styles';

interface IBreadCrumb {
  active?: boolean;
  title?: string;
}

export const BreadCrumbsComponent: React.FC<IBreadCrumb> = ({
  active = true,
  title,
  children,
}) => {
  const location = useLocation();
  const params = useParams();

  const verifyParams = !!Object.keys(params).length;

  let pathname = location.pathname.replace('/', '');

  const splitPathname: any[] = pathname.split('/');

  if (splitPathname) {
    pathname = String(splitPathname[0]);
  }

  return (
    <Container data-testid="breadcrumb">
      {active && (
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          {verifyParams ? (
            <Link underline="hover" color="inherit" href={`/${pathname}`}>
              {t(`navigation.${pathname}`)}
            </Link>
          ) : (
            <Typography color="textPrimary">
              {t(`navigation.${pathname}`)}
            </Typography>
          )}
          {verifyParams && <Typography color="textPrimary">{title}</Typography>}
        </Breadcrumbs>
      )}

      <div className="grid">{children}</div>
    </Container>
  );
};
