import { toast } from 'react-toastify';
import { AppDispatch, AppThunk, RootState } from 'store';
import { loadingPatch } from '../franchises';
import { IPatchBankAccount } from './type';
import { Errors } from 'utils/errors';
import { refreshToken } from 'services/refreshToken';

export function patchBankAccount(data: IPatchBankAccount): AppThunk {
  return async function (dispatch: AppDispatch) {
    const { id, bank_account } = data;

    if (!id) {
      toast.warning('Ops! Você precisa preencher o contrato antes.');
      return;
    }

    try {
      dispatch(loadingPatch(true));
      const { clientApi } = await refreshToken();
      await clientApi.patch(`bank-accounts/contract/${id}`, bank_account);

      toast.success('Atualizado com sucesso.');
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingPatch(false));
    }
  };
}

export const bankAccountsState = (state: RootState) => state.bankAccounts;
