import { format, subYears } from 'date-fns';

interface IYear {
  year: number;
  total: number;
}

export const yearsUtils = (data: IYear) => {
  const { year, total } = data;

  const subYear = Number(format(subYears(new Date(), total), 'yyyy'));

  const arrayYears: string[] = [];

  for (let i = subYear; i < year + 1; i++) {
    arrayYears.push(String(i));
  }

  return arrayYears;
};
