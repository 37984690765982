import { toast } from 'react-toastify';
import { AppDispatch, AppThunk, RootState } from 'store';
import { loading, addGoogleClient, addCep } from '.';
import { IGoogleClient } from './type';
import { refreshToken } from 'services/refreshToken';

export function fetchGoogleClient(): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const { data } = await clientApi.get<IGoogleClient>(
        'public/google_client',
      );

      dispatch(addGoogleClient(data.googleClient));
    } catch (err: any) {
      if (err.response.data?.message) {
        toast.warning(err.response.data.message);
      }
    } finally {
      dispatch(loading(false));
    }
  };
}

export function fetchCep(cep: string): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      const { clientApi } = await refreshToken();
      const response = await clientApi.get(`public/cep/${cep}`);

      dispatch(addCep(response.data));
    } catch (err: any) {
      toast.error(err.response.data.message);
    }
  };
}

export const PublicState = (state: RootState) => state.public;
