import { createSlice } from '@reduxjs/toolkit';

const store = createSlice({
  name: 'openingHours',
  initialState: {
    loading: false,
  },
  reducers: {
    loading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { loading } = store.actions;
export default store.reducer;
