import styled from '@emotion/styled';

export const Container = styled.div`
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    width: 100%;
    height: 48px;
    background: #fff;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

    .logo {
      width: auto;
      text-align: center;
      padding-left: 14px;

      img {
        width: 180px;
      }
    }

    .user {
      display: flex;
      align-items: center;

      button {
        border: 0;
        background: none;
      }

      .description {
        display: flex;
        flex-direction: column;
        text-align: right;
        padding-right: 8px;

        span {
          font-size: 13px;
          margin: 0px;
        }

        small {
          font-size: 11px;
          color: #999;
        }
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
    }
  }
`;
