import { createSlice } from '@reduxjs/toolkit';
import { IContract, IContractType } from './type';
import { IPaginate } from 'utils/type.paginate';

const store = createSlice({
  name: 'contracts',
  initialState: {
    data: [] as IContract[],
    types: [] as IContractType[],
    paginate: {} as IPaginate,
    loading: false,
    loadingPatch: false,
    loadingTransfer: false,
    modalType: false,
    modalNewContract: false,
    modalTransferContract: false,
  },
  reducers: {
    addContracts: (state, action) => {
      state.data = action.payload.data;
      state.paginate = action.payload.paginate;
    },
    addContractTypes: (state, action) => {
      state.types = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
    loadingPatch: (state, action) => {
      state.loadingPatch = action.payload;
    },
    loadingTransfer: (state, action) => {
      state.loadingTransfer = action.payload;
    },
    modalType: (state, action) => {
      state.modalType = action.payload;
    },
    modalNewContract: (state, action) => {
      state.modalNewContract = action.payload;
    },
    modalTransferContract: (state, action) => {
      state.modalTransferContract = action.payload;
    },
  },
});

export const {
  addContracts,
  addContractTypes,
  loading,
  loadingPatch,
  loadingTransfer,
  modalType,
  modalTransferContract,
  modalNewContract,
} = store.actions;
export default store.reducer;
