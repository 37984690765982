const dictionary = {
  dashboard: {
    contractType: {
      new: 'Novo',
      reoponing: 'Reabertura',
      closing: 'Fechamento',
      transfer: 'Transferência',
    },
  },
  navigation: {
    franchises: 'Franqueados',
    contracts: 'Contratos',
    users: 'Usuários',
    administrative: 'Administrativo',
    permissions: 'Permissões',
    settings: 'Configurações',
    owners: 'Proprietários',
  },
};

export default {
  locale: 'pt-BR',
  label: 'Português (Brasil)',
  country: 'BR',
  currency: 'BRL',
  dictionary,
};
