import React from 'react';

import { useFormik } from 'formik';

import { addressForm } from './form';

import { useDispatch } from 'react-redux';

import { IState } from 'store/states/type';
import { alterModal } from 'store/addresses/action';

import { ContentComponent } from 'components/Content';
import { GridLayoutComponent } from 'components/Layout/Grid';
import { InputComponent } from 'components/Input';
import { SelectComponent } from 'components/Select';
import { ButtonComponent } from 'components/Button';

import { ICountries } from 'store/countries/type';
import { IFranchises } from 'store/franchises/type';
import { patchAddress } from 'store/addresses/action';

interface ILocalizationForm {
  loading: boolean;
  countries: ICountries[];
  states: IState[];
  franchise?: IFranchises;
}

export const LocalizationForm: React.FC<ILocalizationForm> = ({
  loading,
  countries,
  states,
  franchise,
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: addressForm(franchise),
    enableReinitialize: true,
    onSubmit: values => {
      if (franchise?.address)
        dispatch(patchAddress(franchise.address.id, values));
    },
  });

  const acronym_state = franchise?.address?.state?.acronym_state || '';

  const filterStates = states
    .filter(item => item.acronym_state.includes(acronym_state))
    .map(item => ({
      value: item.name,
      name: item.acronym_state,
    }));

  const filterCountries = countries.map(item => ({
    value: item.name,
    name: item.id,
  }));

  return (
    <form onSubmit={formik.handleSubmit}>
      <ContentComponent
        filter={loading}
        title="Localização"
        content={
          <ButtonComponent
            type="button"
            size="small"
            onClick={() => dispatch(alterModal(true))}
          >
            Adicionar Novo Endereço
          </ButtonComponent>
        }
      >
        <GridLayoutComponent number={3}>
          <InputComponent
            label="Endereço"
            name="address"
            value={formik.values.address}
            disabled
            onChange={formik.handleChange}
          />
          <InputComponent
            label="Bairro"
            name="neighborhood"
            value={formik.values.neighborhood}
            disabled
            onChange={formik.handleChange}
          />
          <InputComponent
            label="Cidade"
            name="city"
            value={formik.values.city}
            disabled
            onChange={formik.handleChange}
          />
        </GridLayoutComponent>
        <GridLayoutComponent number={2}>
          <SelectComponent
            data={filterStates}
            name="acronym_country"
            title="Estado"
            size="medium"
            value={String(formik.values.state)}
            disabled
            handleChange={formik.handleChange}
          />
          <SelectComponent
            data={filterCountries}
            name="countryId"
            title="País"
            size="medium"
            disabled
            value={formik.values.countryId}
            handleChange={formik.handleChange}
          />
          <InputComponent
            label="Latitude"
            name="latitude"
            value={String(formik.values.latitude)}
            onChange={formik.handleChange}
          />
          <InputComponent
            label="Longitude"
            name="longitude"
            value={String(formik.values.longitude)}
            onChange={formik.handleChange}
          />
        </GridLayoutComponent>

        <ButtonComponent style={{ marginTop: 22 }} type="submit">
          Atualizar
        </ButtonComponent>
      </ContentComponent>
    </form>
  );
};
