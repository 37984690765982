import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IStatus {
  status: string;
}

interface IWeek {
  exists: boolean;
}

interface IStatusContract {
  status: string;
}

interface IGridLoading {
  loading: boolean;
}

export const Status = styled.div<IStatus>`
  display: block;
  padding: 4px 12px;
  border-radius: 6px;
  color: #fff;
  text-align: center;

  ${props =>
    props.status === 'inaugurada' &&
    css`
      background: #26a96d;
    `}

  ${props =>
    props.status === 'a-inaugurar' &&
    css`
      background: #0863a2;
    `}

    ${props =>
    props.status === 'fechada' &&
    css`
      background: #e74a4a;
    `}
`;

export const WeekDiv = styled.div<IWeek>`
  .title-week {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 12px;

    span {
      margin-left: 8px;
      width: 150px;
      height: 4px;
      background: #888;

      ${props =>
        props.exists
          ? css`
              background: #26a96d;
            `
          : css`
              background: #e74a4a;
            `}
    }
  }
  .grid-form-time {
    display: flex;

    div + div {
      margin-left: 12px;
    }
  }
`;

export const GridOwnerOrContract = styled.div`
  display: block;

  p {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .btn-grid-button {
    display: flex;

    button + button {
      margin-left: 8px;
    }
  }
`;

export const GridStatus = styled.div<IStatusContract>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  color: #fff;
  border-radius: 5px;

  ${props =>
    props.status === 'new' &&
    css`
      background: #26a96d;
    `}

  ${props =>
    props.status === 'reoponing' &&
    css`
      background: #e09c14;
    `}

  ${props =>
    props.status === 'transfer' &&
    css`
      background: #125dc3;
    `}

    ${props =>
    props.status === 'closing' &&
    css`
      background: #e74a4a;
    `}

  ${props =>
    props.status === 'nd' &&
    css`
      background: #999;
    `}
`;

export const ContentTitle = styled.div`
  display: block;

  form {
    display: flex;

    button {
      width: 45px;
      border-radius: 4px;
      border: none;
      margin-left: 4px;
      background: #16d69c;
      color: #fff;
    }
  }

  p {
    margin-bottom: 12px;
  }
`;

export const GridLoading = styled.div<IGridLoading>`
  display: block;
  position: relative;
  width: 100%;

  ${props =>
    props.loading &&
    css`
      .loading-blur {
        filter: blur(4px);
      }
    `}

  .grid-loading {
    display: ${props => (props.loading ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    color: #000;
    font-size: 12px;
  }
`;
