import React from 'react';

import api from 'services/api';

import { useFormik } from 'formik';

import { useDispatch } from 'react-redux';

import { postContractNew } from 'store/contracts/action';

import { GridLayoutComponent } from 'components/Layout/Grid';
import { InputComponent } from 'components/Input';
import { GridOwnerOrContract } from 'pages/Franchises/styles';
import { ButtonComponent } from 'components/Button';

import { stringNumber } from 'utils/mask';

import { validationContract, validationVerify } from 'validations/contractForm';
import { toast } from 'react-toastify';
import { DateInputComponent } from 'components/Input/date';

interface IContractModal {
  setModalNewContract: any;
  contractId?: number;
  franchiseEmail: string;
}

export const ContractModal: React.FC<IContractModal> = ({
  setModalNewContract,
  contractId,
  franchiseEmail,
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      fiscal_number: '',
      phone: '',
      birthdate: '',
      date_opening: '',
      cnpj: '',
      company_name: '',
      contractId,
    },
    validationSchema: validationContract(),
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(postContractNew(values, franchiseEmail));
    },
  });

  const handleFetchRFB = async (cnpj: string) => {
    if (cnpj.length < 14) {
      return;
    }

    try {
      const { data } = await api.get(`rfb/${cnpj}`);

      formik.setFieldValue('company_name', data.nome);
    } catch (err) {
      toast.error('Algo deu errado.');
    }
  };

  const {
    verifyContractOwnerName,
    verifyContractOwnerEmail,
    verifyContractOwnerFiscalNumber,
    verifyContractOwnerPhone,
    verifyContractOwnerBirthdate,
    verifyContractDateOpening,
    verifyContractCnpj,
    verifyContractCompanyName,
  } = validationVerify({ formik });

  return (
    <form onSubmit={formik.handleSubmit}>
      <GridOwnerOrContract>
        <p>
          <strong>Responsável</strong>
        </p>
        <GridLayoutComponent number={2}>
          <InputComponent
            label="Nome *"
            name="name"
            error={verifyContractOwnerName}
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <InputComponent
            label="Email *"
            name="email"
            error={verifyContractOwnerEmail}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </GridLayoutComponent>
        <GridLayoutComponent number={3}>
          <InputComponent
            label="CPF *"
            name="fiscal_number"
            mask="999.999.999-99"
            error={verifyContractOwnerFiscalNumber}
            value={formik.values.fiscal_number}
            onChange={formik.handleChange}
          />
          <InputComponent
            label="Telefone ou Celular *"
            name="phone"
            mask={
              formik.values.phone.length > 14
                ? '(99) 99999-9999'
                : '(99) 9999-9999'
            }
            error={verifyContractOwnerPhone}
            value={formik.values.phone}
            onChange={formik.handleChange}
          />
          <DateInputComponent
            label="Nascimento *"
            name="birthdate"
            error={verifyContractOwnerBirthdate}
            value={formik.values.birthdate}
            handleDateChange={(date: any) =>
              formik.setFieldValue('birthdate', date)
            }
          />
        </GridLayoutComponent>
      </GridOwnerOrContract>
      <GridOwnerOrContract style={{ marginTop: 12 }}>
        <p>
          <strong>Contrato</strong>
        </p>
        <GridLayoutComponent number={3}>
          <DateInputComponent
            label="Data de Abertura *"
            name="date_opening"
            error={verifyContractDateOpening}
            handleDateChange={(date: any) =>
              formik.setFieldValue('date_opening', date)
            }
            value={formik.values.date_opening}
          />
          <InputComponent
            label="CNPJ *"
            name="cnpj"
            mask="99.999.999/9999-99"
            error={verifyContractCnpj}
            onBlur={() => handleFetchRFB(stringNumber(formik.values.cnpj))}
            onChange={formik.handleChange}
            value={formik.values.cnpj}
          />
          <InputComponent
            label="Razão Social *"
            error={verifyContractCompanyName}
            value={formik.values.company_name}
            name="company_name"
            onChange={formik.handleChange}
          />
        </GridLayoutComponent>

        <div className="btn-grid-button">
          <ButtonComponent
            type="button"
            color="red"
            onClick={setModalNewContract}
          >
            Cancelar
          </ButtonComponent>
          <ButtonComponent type="submit">Cadastrar</ButtonComponent>
        </div>
      </GridOwnerOrContract>
    </form>
  );
};
