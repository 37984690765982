interface IConfig {
  count: number;
  year: string;
}

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const months = (config: IConfig) => {
  const cfg = config || {};
  const count = cfg.count || 12;
  const { section }: any = cfg;
  const values = [];
  let i;
  let value;

  for (i = 0; i < count; ++i) {
    value = MONTHS[Math.ceil(i) % 12];
    values.push(`${config.year} ${value.substring(0, section)}`);
  }

  return values;
};
