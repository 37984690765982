import { createSlice } from '@reduxjs/toolkit';
import { IFranchises, IFranchiseStatus, IFranchiseType } from './type';
import { IPaginate } from 'utils/type.paginate';

const store = createSlice({
  name: 'franchises',
  initialState: {
    data: [] as IFranchises[],
    show: {} as IFranchises,
    status: [] as IFranchiseStatus[],
    types: [] as IFranchiseType[],
    paginate: {} as IPaginate,
    modalCreate: false,
    loadingPatch: false,
    loading: false,
  },
  reducers: {
    addFranchises: (state, action) => {
      state.data = action.payload.data;
      state.paginate = action.payload.paginate;
    },
    addShow: (state, action) => {
      state.show = action.payload;
    },
    addStatus: (state, action) => {
      state.status = action.payload;
    },
    addTypes: (state, action) => {
      state.types = action.payload;
    },
    modalCreate: (state, action) => {
      state.modalCreate = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
    loadingPatch: (state, action) => {
      state.loadingPatch = action.payload;
    },
  },
});

export const {
  addFranchises,
  addShow,
  addStatus,
  addTypes,
  modalCreate,
  loading,
  loadingPatch,
} = store.actions;
export default store.reducer;
