import React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

interface IDateInput {
  value?: string;
  handleDateChange: any;
  label: string;
  name: string;
  error?: boolean;
  disabled?: boolean;
}

export const DateInputComponent: React.FC<IDateInput> = ({
  value,
  handleDateChange,
  label,
  name,
  error = false,
  disabled = false,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        inputFormat="dd/MM/yyyy"
        onChange={handleDateChange}
        disabled={disabled}
        renderInput={params => (
          <TextField
            {...params}
            name={name}
            error={error}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: 13 },
            }}
          />
        )}
        data-testid="datePicker"
      />
    </LocalizationProvider>
  );
};
