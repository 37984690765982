import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import {
  showFranchises,
  fetchFranchiseStatus,
  fetchFranchiseTypes,
  franchisesState,
} from 'store/franchises/action';

import {
  fetchContractTypes,
  contractsState,
  setModalNewContract,
  setModalTransferContract,
} from 'store/contracts/action';
import { gatewayState, fetchGateway } from 'store/gateway/action';
import { fetchCountries, countriesState } from 'store/countries/action';
import { fetchStates, statesState } from 'store/states/action';
import {
  alterModal,
  fetchAddressTypes,
  addressesState,
} from 'store/addresses/action';

import { LayoutComponent } from 'components/Layout';
import { BreadCrumbsComponent } from 'components/Breadcrumbs';
import { ButtonComponent } from 'components/Button';
import { ModalComponent } from 'components/Modal';
import { NavigationFranchiseComponent } from 'components/Navigation';

import { DataFranchisesForm } from './include/dataFranchisesForm';
import { OwnerAndContractForm } from './include/ownerAndContractForm';
import { LocalizationForm } from './include/localizationForm';
import { OpeningHoursForm } from './include/openingHoursForm';
import { ContractModal } from './include/contractModal';
import { BankAccountForm } from './include/bankAccountForm';
import { GatewayForm } from './include/gatewayForm';
import { TransferContractModal } from './include/transferContractModal';
import { AddressModalForm } from './include/addressModalForm';

const EditFranchise: React.FC = () => {
  const dispatch = useDispatch();
  const { email }: any = useParams();
  const {
    show: franchise,
    types: franchiseTypes,
    status,
    loading,
    loadingPatch: loadingPatchFranchise,
  } = useSelector(franchisesState);
  const {
    modalNewContract,
    loading: loadingContract,
    modalTransferContract,
    loadingTransfer,
  } = useSelector(contractsState);
  const { data: countries } = useSelector(countriesState);
  const { data: gateway } = useSelector(gatewayState);
  const { data: states } = useSelector(statesState);
  const {
    setModal,
    loadingCreate,
    types: addressTypes,
  } = useSelector(addressesState);

  const [menuSelected, setMenuSelected] = useState('franqueados');

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchStates());
    dispatch(fetchContractTypes());
    dispatch(showFranchises(email));
    dispatch(fetchFranchiseStatus());
    dispatch(fetchFranchiseTypes());
    dispatch(fetchGateway());
    dispatch(fetchAddressTypes());
  }, [email]); //eslint-disable-line

  const handleMenuSelect = (value: string) => {
    setMenuSelected(value);
  };

  const verifyIfExistsContract = franchise?.contract
    ? !Object.keys(franchise.contract).length
    : false;

  return (
    <>
      <LayoutComponent loading={loadingPatchFranchise}>
        <BreadCrumbsComponent title={email}>
          <ButtonComponent color="red">Sair</ButtonComponent>
        </BreadCrumbsComponent>

        <NavigationFranchiseComponent
          handleChange={handleMenuSelect}
          menuSelected={menuSelected}
          verifyIfExistsContract={verifyIfExistsContract}
        />

        {menuSelected === 'franqueados' && (
          <DataFranchisesForm
            loading={loading}
            statusSelect={status}
            franchise={franchise}
            franchiseTypes={franchiseTypes}
            type="edit"
          />
        )}

        {menuSelected === 'contratos' && (
          <OwnerAndContractForm
            loading={loading}
            modalNewContract={() => dispatch(setModalNewContract(true))}
            modalTransferContract={() =>
              dispatch(setModalTransferContract(true))
            }
            franchise={franchise}
          />
        )}

        {menuSelected === 'bancario' && (
          <BankAccountForm loading={loading} franchise={franchise} />
        )}

        {menuSelected === 'adquirente' && (
          <GatewayForm
            gatewayLists={gateway}
            loading={loading}
            franchise={franchise}
          />
        )}

        {menuSelected === 'localizacao' && (
          <LocalizationForm
            loading={loading}
            franchise={franchise}
            states={states}
            countries={countries}
          />
        )}

        {menuSelected === 'horario' && (
          <OpeningHoursForm loading={loading} franchise={franchise} />
        )}
      </LayoutComponent>

      <ModalComponent
        title="Adicionar novo Contrato"
        content={
          <ContractModal
            setModalNewContract={() => dispatch(setModalNewContract(false))}
            contractId={franchise.contract?.id}
            franchiseEmail={franchise.email}
          />
        }
        width="880px"
        open={modalNewContract}
        loading={loadingContract}
      />

      <ModalComponent
        title="Transferência de Contrato"
        content={
          <TransferContractModal
            franchiseEmail={franchise.email}
            contractFiscalNumber={franchise.contract?.cnpj}
            modalTransferContract={() =>
              dispatch(setModalTransferContract(false))
            }
          />
        }
        width="880px"
        open={modalTransferContract}
        loading={loadingTransfer}
      />

      <ModalComponent
        title="Adicionar novo Endereço"
        content={
          <AddressModalForm
            modal={() => dispatch(alterModal(false))}
            countries={countries}
            states={states}
            addressTypes={addressTypes}
          />
        }
        width="880px"
        open={setModal}
        loading={loadingCreate}
      />
    </>
  );
};

export default EditFranchise;
