import React from 'react';

import { useFormik } from 'formik';

import { useDispatch } from 'react-redux';

import { patchContractGateway } from 'store/contracts/action';

import { ContentComponent } from 'components/Content';
import { InputComponent } from 'components/Input';
import { GridLayoutComponent } from 'components/Layout/Grid';
import { SelectComponent } from 'components/Select';
import { ButtonComponent } from 'components/Button';

import { IFranchises } from 'store/franchises/type';
import { IGateway } from 'store/gateway/types';
import { gatewayForm } from './form';

interface IGatewayForm {
  loading: boolean;
  franchise?: IFranchises;
  gatewayLists: IGateway[];
}

export const GatewayForm: React.FC<IGatewayForm> = ({
  loading,
  franchise,
  gatewayLists,
}) => {
  const dispatch = useDispatch();

  const gateway = gatewayLists.map(item => ({
    value: item.name,
    name: item.name,
  }));

  const formik = useFormik({
    initialValues: gatewayForm(franchise),
    enableReinitialize: true,
    onSubmit: values => {
      const findGateway = gatewayLists.find(item => item.name === values.name);
      const contractId = franchise?.contract?.id;
      const data = {
        gatewayId: Number(findGateway?.id),
        info_gateway:
          findGateway?.name === 'Rede'
            ? values.infor_gateway.rede
            : values.infor_gateway.getnet,
      };
      if (contractId) {
        dispatch(patchContractGateway(contractId, data));
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ContentComponent filter={loading}>
        <GridLayoutComponent number={1}>
          <SelectComponent
            name="name"
            data={gateway}
            size="medium"
            title="Adquirente *"
            value={String(formik.values.name)}
            handleChange={formik.handleChange}
          />
        </GridLayoutComponent>
        <GridLayoutComponent number={2}>
          {formik.values.name === 'Rede' && (
            <>
              <InputComponent
                name="infor_gateway.rede.pv"
                label="PV"
                value={formik.values.infor_gateway.rede.pv}
                onChange={formik.handleChange}
              />
              <InputComponent
                type="password"
                name="infor_gateway.rede.token"
                label="Token"
                value={formik.values.infor_gateway.rede.token}
                onChange={formik.handleChange}
              />
            </>
          )}

          {formik.values.name === 'Getnet' && (
            <>
              <InputComponent
                name="infor_gateway.getnet.user"
                label="Usuário"
                value={formik.values.infor_gateway.getnet.user}
                onChange={formik.handleChange}
              />
              <InputComponent
                type="password"
                name="infor_gateway.getnet.password"
                label="Senha"
                value={formik.values.infor_gateway.getnet.password}
                onChange={formik.handleChange}
              />
              <InputComponent
                name="infor_gateway.getnet.numberCredencial"
                label="Número do Credencial"
                value={formik.values.infor_gateway.getnet.numberCredencial}
                onChange={formik.handleChange}
              />
              <InputComponent
                name="infor_gateway.getnet.numberLogic"
                label="Número Lógica"
                value={formik.values.infor_gateway.getnet.numberLogic}
                onChange={formik.handleChange}
              />
            </>
          )}
        </GridLayoutComponent>

        <ButtonComponent style={{ marginTop: 22 }} type="submit">
          Atualizar
        </ButtonComponent>
      </ContentComponent>
    </form>
  );
};
