import React, { ButtonHTMLAttributes } from 'react';

import { Button } from './styles';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  color?: 'green' | 'orange' | 'blue' | 'red' | 'gray';
  size?: 'small' | 'large';
  paddingVertical?: 'small' | 'large';
}

export const ButtonComponent: React.FC<IButton> = ({
  loading = false,
  children,
  color = 'green',
  size = 'large',
  paddingVertical = 'large',
  ...rest
}) => {
  return (
    <Button
      {...rest}
      color={color}
      size={size}
      paddingVertical={paddingVertical}
    >
      {loading ? 'carregando...' : children}
    </Button>
  );
};
