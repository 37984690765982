import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

type Data = {
  value: string;
  name: string;
};

interface ISelectComponent {
  name: string;
  title: string;
  value?: string;
  size?: 'small' | 'medium';
  handleChange?: any;
  disabled?: boolean;
  data: Data[];
  error?: boolean;
}

export const SelectComponent: React.FC<ISelectComponent> = ({
  name,
  title,
  value,
  size = 'small',
  handleChange,
  disabled = false,
  data,
  error = false,
}) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel
        error={error}
        style={{ fontSize: 13 }}
        shrink
        aria-label={title}
      >
        {title}
      </InputLabel>
      <Select
        native
        value={value}
        size={size}
        name={name}
        disabled={disabled}
        label={title}
        error={error}
        onChange={handleChange}
      >
        <option aria-label="None" value="" />
        {data.map((item, index) => (
          <option key={String(index)} value={item.name}>
            {item.value}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
