import { toast } from 'react-toastify';
import { AppDispatch, AppThunk, RootState } from 'store';
import { addList, loading } from '.';
import { Errors } from 'utils/errors';
import { refreshToken } from 'services/refreshToken';

export function fetchGateway(): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.get('gateway');
      dispatch(addList(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export const gatewayState = (state: RootState) => state.gateway;
