import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  height: auto;
  padding-top: 52px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;

  .container {
    display: block;
    width: 100%;
    margin: 0px auto;
    padding-top: 26px;
    padding-bottom: 26px;
  }
`;

export const Loading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
`;
