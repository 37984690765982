import { createSlice } from '@reduxjs/toolkit';
import { IGateway } from './types';

const store = createSlice({
  name: 'gateway',
  initialState: {
    data: [] as IGateway[],
    loading: false,
  },
  reducers: {
    addList: (state, action) => {
      state.data = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { loading, addList } = store.actions;
export default store.reducer;
