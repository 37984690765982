import React, { useCallback } from 'react';

import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import api from 'services/api';

import { addressesState, createAddress } from 'store/addresses/action';
import { ICep } from 'store/public/type';

import { ContentComponent } from 'components/Content';
import { GridLayoutComponent } from 'components/Layout/Grid';
import { InputComponent } from 'components/Input';
import { SelectComponent } from 'components/Select';
import { ButtonComponent } from 'components/Button';
import { addressForm } from './form';
import { IFranchises } from 'store/franchises/type';
import { IState } from 'store/states/type';
import { ICountries } from 'store/countries/type';
import { GridButton } from '../styles';
import { IAddressTypes } from 'store/addresses/type';
import { removeLineString } from 'utils/mask';

interface IAddressModalForm {
  states: IState[];
  countries: ICountries[];
  addressTypes: IAddressTypes[];
  modal: any;
}

export const AddressModalForm: React.FC<IAddressModalForm> = ({
  states,
  countries,
  addressTypes,
  modal,
}) => {
  const dispatch = useDispatch();
  const { email }: any = useParams();
  const { loadingCreate } = useSelector(addressesState);
  const franchiseNull = {} as IFranchises;

  const formik = useFormik({
    initialValues: addressForm(franchiseNull),
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(
        createAddress(email, {
          ...values,
          addressTypeId: Number(values.addressTypeId),
        }),
      );
    },
  });

  const filterStates = states.map(item => ({
    value: item.name,
    name: item.acronym_state,
  }));

  const filterCountries = countries.map(item => ({
    value: item.name,
    name: item.id,
  }));

  const addressTypeFilter = addressTypes?.map(item => ({
    value: item.name,
    name: String(item.id),
  }));

  const handleFetchZipCode = useCallback(
    async (event: any) => {
      const { value, name } = event.target;

      const zipCode = removeLineString(value);

      formik.setFieldValue(name, value);

      if (zipCode.length === 8) {
        const { data: cep } = await api.get<ICep>(`public/cep/${zipCode}`);

        formik.setFieldValue('address', cep.logradouro);
        formik.setFieldValue('neighborhood', cep.bairro);
        formik.setFieldValue('city', cep.localidade);
        formik.setFieldValue('state', cep.uf);
      }
    },
    [formik],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <ContentComponent filter={loadingCreate}>
        <GridLayoutComponent number={3}>
          <InputComponent
            label="Cep"
            name="zip_code"
            mask="99999-999"
            value={formik.values.zip_code}
            onChange={handleFetchZipCode}
          />
          <InputComponent
            label="Endereço"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
          />
          <SelectComponent
            name="addressTypeId"
            data={addressTypeFilter || []}
            value={String(formik.values.addressTypeId)}
            handleChange={formik.handleChange}
            size="medium"
            title="Localização da Loja *"
          />
        </GridLayoutComponent>
        <GridLayoutComponent number={2}>
          <InputComponent
            label="Bairro"
            name="neighborhood"
            value={formik.values.neighborhood}
            onChange={formik.handleChange}
          />
          <InputComponent
            label="Cidade"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
          />
        </GridLayoutComponent>
        <GridLayoutComponent number={2}>
          <SelectComponent
            data={filterStates}
            name="acronym_country"
            title="Estado"
            size="medium"
            value={String(formik.values.state)}
            handleChange={formik.handleChange}
          />
          <SelectComponent
            data={filterCountries}
            name="countryId"
            title="País"
            size="medium"
            value={formik.values.countryId}
            handleChange={formik.handleChange}
          />
          <InputComponent
            label="Latitude"
            name="latitude"
            value={String(formik.values.latitude)}
            onChange={formik.handleChange}
          />
          <InputComponent
            label="Longitude"
            name="longitude"
            value={String(formik.values.longitude)}
            onChange={formik.handleChange}
          />
        </GridLayoutComponent>

        <GridButton type>
          <ButtonComponent type="button" color="red" onClick={modal}>
            Cancelar
          </ButtonComponent>
          <ButtonComponent type="submit">Cadastrar</ButtonComponent>
        </GridButton>
      </ContentComponent>
    </form>
  );
};
