import React, { useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';

import { useDispatch, useSelector } from 'react-redux';

import { fetchGoogleClient, PublicState } from 'store/public/action';

import { useAuth } from 'hooks/auth';

import { ButtonComponent } from 'components/Button';

import { Container } from './styles';

const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const { googleClient } = useSelector(PublicState);
  const { signIn, loading } = useAuth();

  useEffect(() => {
    dispatch(fetchGoogleClient());
  }, []); //eslint-disable-line

  const responseGoogle = (response: any) => {
    signIn({ tokenId: response.tokenId });
  };

  return (
    <Container>
      <div className="login">
        <img src="/static/logo.png" alt="Grupo SD" />

        {googleClient && (
          <GoogleLogin
            clientId={googleClient}
            render={renderProps => (
              <ButtonComponent
                type="button"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                loading={loading}
              >
                Entrar
              </ButtonComponent>
            )}
            buttonText="Acessar minha conta"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy="single_host_origin"
            style={{ width: '100%' }}
          />
        )}
      </div>
    </Container>
  );
};

export default SignIn;
