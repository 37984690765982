import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { queryParams, queryURI } from 'queryparamsuri';
import { resultPaginate } from 'page-offset-react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOwners, OwnersState } from 'store/owners/action';

import { BreadCrumbsComponent } from 'components/Breadcrumbs';
import { ContentComponent } from 'components/Content';
import { LayoutComponent } from 'components/Layout';
import { PaginationComponent } from 'components/Pagination';
import { IHandleSearch, Popover } from 'components/Popover';

const Owners: React.FC = () => {
  const { loading, data } = useSelector(OwnersState);
  const dispatch = useDispatch();
  const history = useHistory();

  const { searchURI, page, limit, query } = queryParams({
    params: ['fisca_number', 'email', 'name'],
  });

  const paginate = resultPaginate({
    paginate: {
      limit: data.limit,
      offset: data.offset,
      total: data.total,
    },
    page,
  });

  useEffect(() => {
    dispatch(fetchOwners(searchURI, page, limit));
  }, [searchURI]); //eslint-disable-line

  const handlePage = (value: any) => {
    const params = queryURI({
      name: 'page',
      value,
    });
    history.push(params);
  };

  const handleSearch = (values: IHandleSearch) => {
    const params = queryURI({
      name: values.name,
      value: values.search,
    });

    history.push(params);
  };

  return (
    <LayoutComponent>
      <BreadCrumbsComponent />

      <ContentComponent filter={loading}>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <td>#</td>
              <td>
                CPF{' '}
                <Popover
                  handleSearch={handleSearch}
                  researched={query.fisca_number}
                  name="fiscal_number"
                />
              </td>
              <td>
                Email{' '}
                <Popover
                  handleSearch={handleSearch}
                  researched={query.email}
                  name="email"
                />
              </td>
              <td>
                Nome{' '}
                <Popover
                  handleSearch={handleSearch}
                  researched={query.name}
                  name="name"
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.fiscal_number}</td>
                <td>{item.email}</td>
                <td>{item.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContentComponent>

      <PaginationComponent
        paginate={{
          current_page: paginate.currentPage,
          from: paginate.startIndex,
          last_page: paginate.endPage,
          total: paginate.totalItems,
          to: paginate.endIndex,
          per_page: paginate.startPage,
        }}
        defaultPage={Number(page)}
        handlePage={handlePage}
      />
    </LayoutComponent>
  );
};

export default Owners;
