import React from 'react';
import { Global, css } from '@emotion/react';

import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = () => (
  <Global
    styles={css`
      * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
      }
      a {
        text-decoration: none;
      }
      html,
      body,
      #root {
        min-height: 100%;
      }
      body {
        background: linear-gradient(-90deg, #eeeeee, #e1e1e1);
        -webkit-font-smoothing: antialiased !important;
        font-family: Roboto, sans-serif;
        height: 100%;
        width: 100%;
        font-size: 14px;
        color: #4b4b4b;
      }
      button,
      input,
      textarea {
        font-family: Roboto, sans-serif;
      }
      button {
        cursor: pointer;
      }
      .table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;
      }
      table {
        border-collapse: collapse;
        font-family: Roboto, sans-serif;
        font-size: 14px;
      }
      select option {
        font-size: 16px;
      }
      table {
        display: table;
        border-collapse: separate;
        border-spacing: 2px;
        border-color: grey;
        font-size: 12px;
        width: 100%;
        .table-icon {
          display: flex;
          align-items: center;
          svg {
            margin-right: 4px;
          }
        }
      }

      thead {
        font-weight: bold;
        color: #636363;
        font-size: 14px;
      }
      thead tr td {
        padding: 10px;
      }
      tbody tr td {
        padding: 10px;
        border-top: 1px solid #eee;
        color: #515154;
      }
      .table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.02);
      }
      .table-hover tbody tr:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }
    `}
  />
);

export default GlobalStyle;
