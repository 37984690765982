import React, { useEffect } from 'react';

import { useFormik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import {
  showSettingApiIntegration,
  patchSettingApiIntegration,
  settingState,
} from 'store/settings/action';

import { LayoutComponent } from 'components/Layout';
import { BreadCrumbsComponent } from 'components/Breadcrumbs';
import { ContentComponent } from 'components/Content';
import { GridLayoutComponent } from 'components/Layout/Grid';
import { InputComponent } from 'components/Input';
import { ButtonComponent } from 'components/Button';

const ApiIntegration: React.FC = () => {
  const { apiIntegration } = useSelector(settingState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showSettingApiIntegration());
  }, []); //eslint-disable-line

  const formik = useFormik({
    initialValues: {
      username: apiIntegration?.username || '',
      password: apiIntegration?.password || '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(patchSettingApiIntegration(values));
    },
  });

  return (
    <LayoutComponent>
      <BreadCrumbsComponent title="Api Integration">
        <ButtonComponent color="red">Sair</ButtonComponent>
      </BreadCrumbsComponent>

      <form onSubmit={formik.handleSubmit}>
        <ContentComponent>
          <GridLayoutComponent number={2}>
            <InputComponent
              label="Username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
            />
            <InputComponent
              label="Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </GridLayoutComponent>

          <ButtonComponent style={{ marginTop: 22 }} type="submit">
            Atualizar
          </ButtonComponent>
        </ContentComponent>
      </form>
    </LayoutComponent>
  );
};

export default ApiIntegration;
