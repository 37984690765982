import { toast } from 'react-toastify';
import { refreshToken } from 'services/refreshToken';
import { AppDispatch, AppThunk, RootState } from 'store';
import { Errors } from 'utils/errors';
import { loading, loadingCreate, setModal, addTypes } from '.';
import { addShow } from '../franchises';

import { ICreateAddress, IPatchAddress } from './type';

export function patchAddress(id: number, data: IPatchAddress): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      await clientApi.patch(`addresses/${id}`, data);

      toast.success('Atualizado com sucesso.');
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function createAddress(email: string, data: ICreateAddress): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loadingCreate(true));
      const { clientApi } = await refreshToken();
      await clientApi.post(`addresses/franchise/${email}`, data);
      const response = await clientApi.get(`franchises/email/${email}`);

      dispatch(addShow(response.data));
      dispatch(setModal(false));
      toast.success('Adicionado com sucesso.');
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingCreate(false));
    }
  };
}

export function alterModal(event: boolean): AppThunk {
  return function (dispatch: AppDispatch) {
    dispatch(setModal(event));
  };
}

export function fetchAddressTypes(): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      const { clientApi } = await refreshToken();
      const response = await clientApi.get('addresses/types');

      dispatch(addTypes(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    }
  };
}

export const addressesState = (state: RootState) => state.addresses;
