import { createSlice } from '@reduxjs/toolkit';
import { ICep } from './type';

const store = createSlice({
  name: 'public',
  initialState: {
    googleClient: '',
    cep: {} as ICep,
    loading: false,
  },
  reducers: {
    addGoogleClient: (state, action) => {
      state.googleClient = action.payload;
    },
    addCep: (state, action) => {
      state.cep = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { addGoogleClient, addCep, loading } = store.actions;
export default store.reducer;
