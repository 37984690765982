import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { useParams, useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { IRoles } from 'store/roles/type';

import { setModalAction, createUser, updateUser } from 'store/users/action';

import { ContentComponent } from 'components/Content';
import { GridLayoutComponent } from 'components/Layout/Grid';
import { InputComponent } from 'components/Input';
import { ButtonComponent } from 'components/Button';
import { MultipleSelect } from 'components/Select/chip';

import { GridButton } from './styles';
import { SelectChangeEvent } from '@mui/material';
import { IUser } from 'store/users/type';

interface ICreateOrEdit {
  loading?: boolean;
  user?: IUser;
  roles: IRoles[];
  type: 'create' | 'edit';
}

export const CreateOrEdit: React.FC<ICreateOrEdit> = ({
  loading = false,
  user,
  roles,
  type,
}) => {
  const dispatch = useDispatch();
  const [roleId, setRoleId] = useState<string[]>([]);

  const history = useHistory();
  const { id }: any = useParams();

  useEffect(() => {
    if (user && Object.keys(user).length) {
      const slugs = user.roles.map(item => item.slug);
      setRoleId(slugs);
    }
  }, [user]);

  const handleChangeRole = (event: SelectChangeEvent<typeof roleId>) => {
    const {
      target: { value },
    } = event;
    setRoleId(typeof value === 'string' ? value.split(',') : value);
  };

  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      if (type === 'create')
        dispatch(createUser({ ...values, roles: roleId }, history));
      if (type === 'edit')
        dispatch(updateUser(+id, { ...values, roles: roleId }));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ContentComponent filter={loading}>
        <GridLayoutComponent number={2}>
          <InputComponent
            label="Nome *"
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          <InputComponent
            label="Email *"
            type="text"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </GridLayoutComponent>

        <GridLayoutComponent number={1}>
          <MultipleSelect
            title="Adicionar Permissão ao usuário"
            name="permissions"
            value={roleId}
            data={roles.map(item => ({
              value: item.name,
              name: String(item.slug),
            }))}
            handleChange={handleChangeRole}
          />
        </GridLayoutComponent>

        <GridButton type={type === 'create'}>
          {type === 'create' && (
            <ButtonComponent
              type="button"
              color="red"
              onClick={() => dispatch(setModalAction(false))}
            >
              Cancelar
            </ButtonComponent>
          )}

          <ButtonComponent type="submit">
            {type === 'edit' ? 'Atualizar' : 'Adicionar'}
          </ButtonComponent>
        </GridButton>
      </ContentComponent>
    </form>
  );
};
