import { createSlice } from '@reduxjs/toolkit';
import { ICountries } from './type';

const store = createSlice({
  name: 'contries',
  initialState: {
    data: [] as ICountries[],
    loading: false,
  },
  reducers: {
    addCountries: (state, action) => {
      state.data = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { addCountries, loading } = store.actions;
export default store.reducer;
