import { toast } from 'react-toastify';
import { History } from 'history';
import { AppDispatch, AppThunk, RootState } from 'store';

import {
  addFranchises,
  addShow,
  addStatus,
  addTypes,
  modalCreate,
  loading,
  loadingPatch,
} from '.';

import { paramsFetch, IParams } from 'utils/params';
import { ICreateFranchise, IPatchFranchise } from './type';
import { convertEmail } from 'utils/convertEmail';
import { Errors } from 'utils/errors';
import { refreshToken } from 'services/refreshToken';

interface ICreateHistory {
  data: ICreateFranchise;
  history: History;
}

export function fetchFranchises(data?: IParams): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));

      const params = paramsFetch(data);

      const { clientApi } = await refreshToken();
      const response = await clientApi.get('franchises', { params });

      dispatch(addFranchises(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function showFranchises(email: string): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.get(`franchises/email/${email}`);

      dispatch(addShow(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function fetchFranchiseStatus(): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      const { clientApi } = await refreshToken();
      const response = await clientApi.get('franchise-statuses');

      dispatch(addStatus(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    }
  };
}

export function fetchFranchiseTypes(): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      const { clientApi } = await refreshToken();
      const response = await clientApi.get('franchise-types');

      dispatch(addTypes(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    }
  };
}

export function patchFranchise(email: string, data: IPatchFranchise): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loadingPatch(true));
      const { clientApi } = await refreshToken();
      await clientApi.patch(`franchises/email/${email}`, data);

      toast.success('Atualizado com sucesso.');
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingPatch(false));
    }
  };
}

export function createFranchise(create: ICreateHistory): AppThunk {
  return async function (dispatch: AppDispatch) {
    const { history, data } = create;
    try {
      dispatch(loadingPatch(true));
      const { clientApi } = await refreshToken();
      await clientApi.post('franchises/create', data);

      toast.success('Franqueado adicionado com sucesso.');
      dispatch(modalCreate(false));
      history.push(`franchises/edit/${convertEmail(data.email)}`);
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingPatch(false));
    }
  };
}

export function openModalCreate(data: boolean): AppThunk {
  return function (dispatch: AppDispatch) {
    dispatch(modalCreate(data));
  };
}

export const franchisesState = (state: RootState) => state.franchises;
