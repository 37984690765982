import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';

import Dashboard from 'pages/Dashboard';
import SignIn from 'pages/SignIn';
import Franchises from 'pages/Franchises';
import Contracts from 'pages/Contracts';
import Users from 'pages/Users';
import EditUser from 'pages/Users/edit';
import EditFranchise from 'pages/Franchises/EditOrCreate/edit';
import Permissions from 'pages/Permissions';
import PermissionsCreate from 'pages/Permissions/create';
import EditPermissions from 'pages/Permissions/edit';
import Setttings from 'pages/Settings';
import EditSettingApiIntegration from 'pages/Settings/api_integration';
import Owners from 'pages/Owners';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" component={SignIn} />

    <Route path="/" exact component={Dashboard} isPrivate />
    <Route path="/contracts" component={Contracts} isPrivate />

    <Route path="/users" exact component={Users} isPrivate />
    <Route path="/users/:id" exact component={EditUser} isPrivate />

    <Route path="/franchises" exact component={Franchises} isPrivate />
    <Route
      path="/franchises/edit/:email"
      exact
      component={EditFranchise}
      isPrivate
    />

    <Route path="/permissions" exact component={Permissions} isPrivate />
    <Route
      path="/permissions/create"
      exact
      component={PermissionsCreate}
      isPrivate
    />
    <Route
      path="/permissions/edit/:id"
      exact
      component={EditPermissions}
      isPrivate
    />
    <Route path="/settings" exact component={Setttings} isPrivate />
    <Route
      path="/settings/api-integration"
      exact
      component={EditSettingApiIntegration}
      isPrivate
    />
    <Route path="/owners" exact component={Owners} isPrivate />

    <Redirect to="/404" />
  </Switch>
);

export default Routes;
