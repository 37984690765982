import styled from '@emotion/styled';

interface IOpen {
  open: boolean;
  width: string;
}

export const Container = styled.div<IOpen>`
  display: ${props => (props.open ? 'flex' : 'none')};
  flex: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  z-index: 99;

  .modal {
    display: flex;
    width: 100%;
    max-width: ${props => props.width};

    .modal-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 16px;
      border-radius: 6px;
      background: #fff;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 6px;
      border-bottom: 1px dotted #999;
      margin-bottom: 8px;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;

      button + button {
        margin-left: 4px;
      }
    }
  }
`;
