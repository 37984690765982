import React, { useEffect } from 'react';

import { MdMoreVert } from 'react-icons/md';

import { useAuth } from 'hooks/auth';

import { Container } from './styles';

export const HeaderComponent: React.FC = () => {
  const { me, handleLoadUserMe, handleLogged } = useAuth();

  useEffect(() => {
    handleLoadUserMe();
    handleLogged();
  }, []); //eslint-disable-line

  return (
    <Container>
      <div className="header">
        <div className="logo">
          <img src="/static/logo.svg" alt="Grupo SD" />
        </div>
        <div className="user">
          <div className="description">
            <span>{me?.name}</span>
            <small>{me?.email}</small>
          </div>
          <img src="/static/notimage.png" alt="SD" />

          <button type="button">
            <MdMoreVert size={24} />
          </button>
        </div>
      </div>
    </Container>
  );
};
