import { ICountries } from 'store/countries/type';
import { IFranchises } from 'store/franchises/type';

export const dataFranchiseForm = (
  franchise?: IFranchises,
  countries?: ICountries[],
) => {
  const countryId = countries
    ? countries.find(item => item.acronym_country === 'BR')?.id
    : '';

  return {
    email: franchise?.email || '',
    phone: franchise?.phone || '',
    avant_id: franchise?.avant_id ? String(franchise?.avant_id) : '',
    whatsappBusiness: franchise?.whatsappBusiness || '',
    showWhatsAppBusiness: franchise?.showWhatsAppBusiness || false,
    foccoCode: franchise?.foccoCode ? Number(franchise?.foccoCode) : 0,
    facebook: franchise?.facebook ? franchise?.facebook.replace(/"/g, '') : '',
    instagram: franchise?.instagram
      ? franchise?.instagram.replace(/"/g, '')
      : '',
    franchiseStatusId: franchise?.franchiseStatus?.id || '',
    franchiseTypeId: franchise?.franchiseType?.id || '',
    address: {
      city: franchise?.address?.city || '',
      neighborhood: franchise?.address?.neighborhood || '',
      countryId: franchise?.address?.state?.country?.id || countryId,
      address: franchise?.address?.address || '',
      latitude: Number(franchise?.address?.latitude) || 0,
      longitude: Number(franchise?.address?.longitude) || 0,
      state: franchise?.address?.state?.acronym_state || '',
      zip_code: franchise?.address?.zip_code || '',
      addressTypeId: franchise?.address?.address_type?.id || '',
    },
  };
};

export const ownerAndContractForm = (franchise?: IFranchises) => {
  const convertGateway = franchise?.contract?.info_gateway;

  return {
    date_opening: franchise?.contract?.date_opening || '',
    cnpj: franchise?.contract?.cnpj || '',
    company_name: franchise?.contract?.company_name || '',
    owner: {
      id: franchise?.contract?.owner?.id || '',
      name: franchise?.contract?.owner?.name || '',
      email: franchise?.contract?.owner?.email || '',
      fiscal_number: franchise?.contract?.owner?.fiscal_number || '',
      phone: franchise?.contract?.owner?.phone || '',
      birthdate: franchise?.contract?.owner?.birthdate || '',
    },
    contractType: {
      id: String(franchise?.contract?.contractType?.id) || '',
      description: franchise?.contract?.contractType?.description || '',
    },
    bank_account: {
      bank_number: franchise?.contract?.bank_account?.bank_number || '',
      bank_name: franchise?.contract?.bank_account?.bank_name || '',
      bank_account: franchise?.contract?.bank_account?.bank_account || '',
      bank_branch: franchise?.contract?.bank_account?.bank_branch || '',
    },
    gateway: {
      id: String(franchise?.contract?.gateway?.id) || '',
    },
    rede: {
      pv: convertGateway?.pv || '',
      token: convertGateway?.token || '',
    },
    getnet: {
      user: convertGateway?.user || '',
      password: convertGateway?.password || '',
      numberCredencial: convertGateway?.numberCredencial || '',
      numberLogic: convertGateway?.numberLogic || '',
    },
  };
};

export const bankAccountForm = (franchise?: IFranchises) => {
  return {
    bankAccountId: String(franchise?.contract?.bank_account?.id) || '',
    bank_number: String(franchise?.contract?.bank_account?.bank_number) || '',
    bank_name: franchise?.contract?.bank_account?.bank_name || '',
    bank_account: franchise?.contract?.bank_account?.bank_account || '',
    bank_branch: franchise?.contract?.bank_account?.bank_branch || '',
  };
};

export const gatewayForm = (franchise?: IFranchises) => {
  return {
    name: franchise?.contract?.gateway?.name || '',
    infor_gateway: {
      rede: {
        pv: franchise?.contract?.info_gateway?.pv || '',
        token: franchise?.contract?.info_gateway?.pv || '',
      },
      getnet: {
        user: franchise?.contract?.info_gateway?.user || '',
        password: franchise?.contract?.info_gateway?.password || '',
        numberCredencial:
          franchise?.contract?.info_gateway?.numberCredencial || '',
        numberLogic: franchise?.contract?.info_gateway?.numberLogic || '',
      },
    },
  };
};

export const addressForm = (franchise?: IFranchises) => {
  return {
    city: franchise?.address?.city || '',
    neighborhood: franchise?.address?.neighborhood || '',
    countryId: franchise?.address?.state?.country?.id || '1',
    address: franchise?.address?.address || '',
    latitude: Number(franchise?.address?.latitude) || 0,
    longitude: Number(franchise?.address?.longitude) || 0,
    state: franchise?.address?.state?.acronym_state,
    addressTypeId: franchise?.address?.address_type?.id || '',
    zip_code: franchise?.address?.zip_code || '',
  };
};

export const openingHoursForm = (franchise?: IFranchises) => {
  return [
    {
      id: franchise?.openingHours?.find(item => item.weekDay === 0)?.id,
      weekDay: 0,
      begin:
        franchise?.openingHours
          ?.find(item => item.weekDay === 0)
          ?.begin.replace(/"/g, '') || '',
      end:
        franchise?.openingHours
          ?.find(item => item.weekDay === 0)
          ?.end.replace(/"/g, '') || '',
    },
    {
      id: franchise?.openingHours?.find(item => item.weekDay === 1)?.id,
      weekDay: 1,
      begin:
        franchise?.openingHours
          ?.find(item => item.weekDay === 1)
          ?.begin.replace(/"/g, '') || '',
      end:
        franchise?.openingHours
          ?.find(item => item.weekDay === 1)
          ?.end.replace(/"/g, '') || '',
    },
    {
      id: franchise?.openingHours?.find(item => item.weekDay === 2)?.id,
      weekDay: 2,
      begin:
        franchise?.openingHours
          ?.find(item => item.weekDay === 2)
          ?.begin.replace(/"/g, '') || '',
      end:
        franchise?.openingHours
          ?.find(item => item.weekDay === 2)
          ?.end.replace(/"/g, '') || '',
    },
    {
      id: franchise?.openingHours?.find(item => item.weekDay === 3)?.id,
      weekDay: 3,
      begin:
        franchise?.openingHours
          ?.find(item => item.weekDay === 3)
          ?.begin.replace(/"/g, '') || '',
      end:
        franchise?.openingHours
          ?.find(item => item.weekDay === 3)
          ?.end.replace(/"/g, '') || '',
    },
    {
      id: franchise?.openingHours?.find(item => item.weekDay === 4)?.id,
      weekDay: 4,
      begin:
        franchise?.openingHours
          ?.find(item => item.weekDay === 4)
          ?.begin.replace(/"/g, '') || '',
      end:
        franchise?.openingHours
          ?.find(item => item.weekDay === 4)
          ?.end.replace(/"/g, '') || '',
    },
    {
      id: franchise?.openingHours?.find(item => item.weekDay === 5)?.id,
      weekDay: 5,
      begin:
        franchise?.openingHours
          ?.find(item => item.weekDay === 5)
          ?.begin.replace(/"/g, '') || '',
      end:
        franchise?.openingHours
          ?.find(item => item.weekDay === 5)
          ?.end.replace(/"/g, '') || '',
    },
    {
      id: franchise?.openingHours?.find(item => item.weekDay === 6)?.id,
      weekDay: 6,
      begin:
        franchise?.openingHours
          ?.find(item => item.weekDay === 6)
          ?.begin.replace(/"/g, '') || '',
      end:
        franchise?.openingHours
          ?.find(item => item.weekDay === 6)
          ?.end.replace(/"/g, '') || '',
    },
  ];
};
