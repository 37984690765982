import React from 'react';

import {
  MdCheck,
  MdPerson,
  MdAccountBalance,
  MdAttachMoney,
  MdMap,
  MdQueryBuilder,
} from 'react-icons/md';

import { Navigation } from './styles';

interface INavigationFranchise {
  menuSelected: string;
  verifyIfExistsContract: boolean;
  handleChange: any;
}

export const NavigationFranchiseComponent: React.FC<INavigationFranchise> = ({
  menuSelected,
  verifyIfExistsContract,
  handleChange,
}) => {
  return (
    <Navigation data-testid="navigation">
      <button
        type="button"
        className={menuSelected === 'franqueados' ? 'active' : 'nd'}
        onClick={() => handleChange('franqueados')}
      >
        <MdCheck /> Dados do Franqueados
      </button>
      <button
        type="button"
        className={menuSelected === 'contratos' ? 'active' : 'nd'}
        onClick={() => handleChange('contratos')}
      >
        <MdPerson /> Responsável e Contrato
      </button>
      <button
        type="button"
        className={menuSelected === 'bancario' ? 'active' : 'nd'}
        disabled={verifyIfExistsContract}
        onClick={() =>
          !verifyIfExistsContract ? handleChange('bancario') : {}
        }
      >
        <MdAccountBalance /> Dados Bancário
      </button>
      <button
        type="button"
        className={menuSelected === 'adquirente' ? 'active' : 'nd'}
        disabled={verifyIfExistsContract}
        onClick={() =>
          !verifyIfExistsContract ? handleChange('adquirente') : {}
        }
      >
        <MdAttachMoney /> Adquirente
      </button>
      <button
        type="button"
        className={menuSelected === 'localizacao' ? 'active' : 'nd'}
        onClick={() => handleChange('localizacao')}
      >
        <MdMap /> Localização
      </button>
      <button
        type="button"
        className={menuSelected === 'horario' ? 'active' : 'nd'}
        onClick={() => handleChange('horario')}
      >
        <MdQueryBuilder /> Horário de Funcionamento
      </button>
    </Navigation>
  );
};
