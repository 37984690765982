import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade } from 'polished';

interface IButton {
  color: 'green' | 'orange' | 'blue' | 'red' | 'gray';
  size: 'small' | 'large';
  paddingVertical: 'small' | 'large';
}

export const Button = styled.button<IButton>`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  transition: background-color 0.2s;

  /* :disabled {
    background: #818181;
    cursor: no-drop;

    :hover {
      background: #818181;
    }
  } */

  svg {
    margin-right: 4px;
  }

  ${props =>
    props.paddingVertical === 'large' &&
    css`
      padding-left: 22px;
      padding-right: 22px;
    `}

  ${props =>
    props.paddingVertical === 'small' &&
    css`
      padding-left: 8px;
      padding-right: 8px;
    `}

  ${props =>
    props.size === 'large' &&
    css`
      width: 100%;
      height: 40px;
    `}

  ${props =>
    props.size === 'small' &&
    css`
      width: auto;
      height: 26px;
    `}

  ${props =>
    props.color === 'green' &&
    css`
      background: #6aa57b;

      &:hover {
        background: ${shade(0.2, '#6AA57B')};
      }
    `}

  ${props =>
    props.color === 'orange' &&
    css`
      background: #e09c14;

      &:hover {
        background: ${shade(0.2, '#E09C14')};
      }
    `}

    ${props =>
    props.color === 'blue' &&
    css`
      background: #125dc3;

      &:hover {
        background: ${shade(0.2, '#125DC3')};
      }
    `}

    ${props =>
    props.color === 'red' &&
    css`
      background: #f32765;

      &:hover {
        background: ${shade(0.2, '#F32765')};
      }
    `}

    ${props =>
    props.color === 'gray' &&
    css`
      background: #818181;

      &:hover {
        background: ${shade(0.2, '#818181')};
      }
    `}
`;
