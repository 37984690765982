import { addDays } from 'date-fns';
import { parseCookies, setCookie, destroyCookie } from 'nookies';

interface ICookie {
  name: string;
  data: string;
  expireDay?: number;
}

export const addCookie = ({ name, data, expireDay }: ICookie) => {
  if (expireDay) {
    setCookie(undefined, name, data, {
      path: '/',
      expires: addDays(new Date(), expireDay),
    });

    return;
  }

  setCookie(undefined, name, data, {
    path: '/',
    maxAge: 20, // 2secondes
  });
};

export const getCookie = (name: string): string => {
  const cookie = parseCookies();
  const getCookie = cookie[name];

  return getCookie;
};

export const removeCookie = (name: string) => {
  destroyCookie(undefined, name);
};
