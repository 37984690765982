export interface IParams {
  limit?: string;
  page?: string;
  email?: string;
  address?: string;
  status?: string;
  type?: string;
  cnpj?: string;
  company_name?: string;
}

export const paramsFetch = (data?: IParams) => {
  let params: any = {};

  if (data) {
    if (data.limit) {
      params = {
        ...params,
        limit: Number(data.limit),
      };
    }

    if (data.page) {
      params = {
        ...params,
        page: Number(data.page),
      };
    }

    if (data.email) {
      params = {
        ...params,
        email: data.email,
      };
    }

    if (data.address) {
      params = {
        ...params,
        address: data.address,
      };
    }

    if (data.status) {
      params = {
        ...params,
        status: data.status,
      };
    }

    if (data.type) {
      params = {
        ...params,
        type: data.type,
      };
    }

    if (data.cnpj) {
      params = {
        ...params,
        cnpj: data.cnpj,
      };
    }

    if (data.company_name) {
      params = {
        ...params,
        company_name: data.company_name,
      };
    }
  }

  return params;
};
