import React, { useEffect, useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { PermissionsProps, Checkbox } from 'role-permissions-react';

import { updateRole, createRole } from 'store/roles/action';

import { ContentComponent } from 'components/Content';
import { InputComponent } from 'components/Input';
import { GridLayoutComponent } from 'components/Layout/Grid';
import { ButtonComponent } from 'components/Button';

import { GridButton, GridPermission } from './styles';

interface ICreateOrEdit {
  roles: PermissionsProps[];
  name?: string;
  type: 'create' | 'edit';
}

export const CreateOrEdit: React.FC<ICreateOrEdit> = ({
  roles,
  name,
  type,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: any = useParams();

  const [check, setCheck] = useState<PermissionsProps[]>([]);

  useEffect(() => {
    if (roles) setCheck(roles);
  }, [roles]);

  const formik = useFormik({
    initialValues: {
      name: name || '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      if (type === 'edit')
        dispatch(
          updateRole(+id, {
            ...values,
            permissions: check,
          }),
        );

      if (type === 'create')
        dispatch(
          createRole(
            {
              ...values,
              permissions: check,
            },
            history,
          ),
        );
    },
  });

  const handleCheckbox = (event: PermissionsProps[]) => {
    setCheck(event);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <ContentComponent filter={false}>
        <GridLayoutComponent number={1}>
          <InputComponent
            label="Nome *"
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
        </GridLayoutComponent>

        <GridLayoutComponent number={1}>
          <h3>Permissões</h3>

          <GridPermission>
            {roles &&
              roles.map((item, index) => {
                return (
                  <li key={item.id}>
                    <strong>{item.name}</strong>

                    <Checkbox
                      handleChange={handleCheckbox}
                      data={{
                        index,
                        item,
                        permissions: check,
                        alter_methods: {
                          get: 'EDITAR',
                          post: 'CRIAR',
                          update: 'ATUALIZAR',
                          delete: 'DELETAR',
                        },
                      }}
                    />
                  </li>
                );
              })}
          </GridPermission>

          <GridButton type={type === 'create'}>
            <ButtonComponent type="submit">
              {type === 'edit' ? 'Atualizar' : 'Adicionar'}
            </ButtonComponent>
          </GridButton>
        </GridLayoutComponent>
      </ContentComponent>
    </form>
  );
};
