import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { MdControlPoint, MdDelete, MdModeEdit } from 'react-icons/md';

import { fetchRoles, rolesState } from 'store/roles/action';

import { LayoutComponent } from 'components/Layout';
import { BreadCrumbsComponent } from 'components/Breadcrumbs';
import { ButtonComponent } from 'components/Button';
import { SearchComponent } from 'components/Layout/Search';
import { ContentComponent } from 'components/Content';

import { BtnGrid } from './styles';

const Permissions: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: roles, loading } = useSelector(rolesState);

  useEffect(() => {
    dispatch(fetchRoles());
  }, []); //eslint-disable-line

  return (
    <LayoutComponent>
      <BreadCrumbsComponent>
        <ButtonComponent onClick={() => history.push('/permissions/create')}>
          <MdControlPoint /> Adicionar
        </ButtonComponent>
      </BreadCrumbsComponent>

      <SearchComponent />

      <ContentComponent filter={loading}>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <td>#</td>
              <td>Nome</td>
              <td>Slug</td>
              <td>Data de Criação</td>
              <td>Ação</td>
            </tr>
          </thead>
          <tbody>
            {roles.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.slug}</td>
                <td>{item.created_at}</td>
                <td align="right">
                  <BtnGrid>
                    <ButtonComponent
                      size="small"
                      paddingVertical="small"
                      onClick={() =>
                        history.push(`/permissions/edit/${item.id}`)
                      }
                    >
                      <MdModeEdit /> Editar
                    </ButtonComponent>
                    <ButtonComponent
                      size="small"
                      paddingVertical="small"
                      onClick={() => console.log('teste')}
                      color="red"
                    >
                      <MdDelete /> Excluir
                    </ButtonComponent>
                  </BtnGrid>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContentComponent>
    </LayoutComponent>
  );
};

export default Permissions;
