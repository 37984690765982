import VMasker from 'vanilla-masker';
import moment from 'moment';

export const customMask = (value: any, mask: string) =>
  value && VMasker.toNumber(value) ? VMasker.toPattern(value, mask) : '';

export const stringNumber = (value: string) => value.replace(/[^\d]+/g, '');

export const formatDatePtBr = (value: string) => {
  return moment(value).format('YYYY-MM-DD');
};

export const removeLineString = (value: string) => {
  value = value.replace('-', '');

  return value;
};
