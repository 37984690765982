import * as Yup from 'yup';

interface IFormik {
  formik: any;
}

export const validationOwner = () => {
  const validation = Yup.object().shape({
    name: Yup.string().required('Ops! Preencha o nome do proprietário.'),
    email: Yup.string().required('Ops! Preencha o seu email do proprietário.'),
    fiscal_number: Yup.string().required(
      'Ops! Preencha o cpf do proprietário.',
    ),
    phone: Yup.string().required('Ops! Preencha o telefone do proprietário.'),
    birthdate: Yup.date().required(
      'Ops! Preencha a data de nascimento do propriétario.',
    ),
  });

  return validation;
};

export const validationVerify = ({ formik }: IFormik) => {
  const verifyContractOwnerName = !!(
    formik.touched?.name && formik.errors?.name
  );
  const verifyContractOwnerEmail = !!(
    formik.touched?.email && formik.errors?.email
  );
  const verifyContractOwnerFiscalNumber = !!(
    formik.touched?.fiscal_number && formik.errors?.fiscal_number
  );
  const verifyContractOwnerPhone = !!(
    formik.touched?.phone && formik.errors?.phone
  );
  const verifyContractOwnerBirthdate = !!(
    formik.touched?.birthdate && formik.errors?.birthdate
  );

  return {
    verifyContractOwnerName,
    verifyContractOwnerEmail,
    verifyContractOwnerFiscalNumber,
    verifyContractOwnerPhone,
    verifyContractOwnerBirthdate,
  };
};
