import { createSlice } from '@reduxjs/toolkit';
import { IApiIntegration } from './type';

const store = createSlice({
  name: 'settings',
  initialState: {
    apiIntegration: {} as IApiIntegration,
    loading: false,
  },
  reducers: {
    addApiIntegration: (state, action) => {
      state.apiIntegration = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { addApiIntegration, loading } = store.actions;
export default store.reducer;
