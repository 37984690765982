import { toast } from 'react-toastify';
import { AppDispatch, AppThunk, RootState } from 'store';
import { addDashboard, loading } from '.';
import { Errors } from 'utils/errors';
import { refreshToken } from 'services/refreshToken';

export function fetchDashboard(year?: string): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.get(`dashboard?take=10&year=${year}`);

      dispatch(addDashboard(response.data));
    } catch (err: any) {
      const error = Errors(err);
      if (error.statusCode === 403) {
        return;
      }
      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export const dashboardState = (state: RootState) => state.dashboard;
