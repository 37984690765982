import React from 'react';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { patchOpeningHours } from 'store/openingHours/action';
import { IFranchises } from 'store/franchises/type';

import { openingHoursForm } from './form';

import { ContentComponent } from 'components/Content';
import { GridLayoutComponent } from 'components/Layout/Grid';
import { InputComponent } from 'components/Input';

import { weeksName } from 'utils/date';
import { WeekDiv } from 'pages/Franchises/styles';
import { useFormik } from 'formik';
import { ButtonComponent } from 'components/Button';

interface IOpeningHoursForm {
  loading: boolean;
  franchise?: IFranchises;
}

export const OpeningHoursForm: React.FC<IOpeningHoursForm> = ({
  loading,
  franchise,
}) => {
  const dispatch = useDispatch();
  const { email }: any = useParams();

  const formik = useFormik({
    initialValues: openingHoursForm(franchise),
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(patchOpeningHours({ email, openingHours: values }));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ContentComponent filter={loading}>
        <GridLayoutComponent number={3}>
          {weeksName().map((item, index) => {
            const verifyExistsBeginAndEnd = !!(
              formik.values[item.weekDay].begin &&
              formik.values[item.weekDay].end
            );

            return (
              <WeekDiv key={String(index)} exists={verifyExistsBeginAndEnd}>
                <div className="title-week">
                  {item.name}: <span />
                </div>
                <div className="grid-form-time">
                  <InputComponent
                    type="time"
                    label="De"
                    name={`[${item.weekDay}].begin`}
                    value={formik.values[item.weekDay].begin}
                    onChange={formik.handleChange}
                  />
                  <InputComponent
                    type="time"
                    label="Até"
                    name={`[${item.weekDay}].end`}
                    value={formik.values[item.weekDay].end}
                    onChange={formik.handleChange}
                  />
                </div>
              </WeekDiv>
            );
          })}
        </GridLayoutComponent>

        <ButtonComponent style={{ marginTop: 22 }} type="submit">
          Atualizar
        </ButtonComponent>
      </ContentComponent>
    </form>
  );
};
