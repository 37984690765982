import { createSlice } from '@reduxjs/toolkit';
import { IAddressTypes } from './type';

const store = createSlice({
  name: 'addresses',
  initialState: {
    loading: false,
    loadingCreate: false,
    setModal: false,
    types: [] as IAddressTypes[],
  },
  reducers: {
    loading: (state, action) => {
      state.loading = action.payload;
    },
    loadingCreate: (state, action) => {
      state.loadingCreate = action.payload;
    },
    setModal: (state, action) => {
      state.setModal = action.payload;
    },
    addTypes: (state, action) => {
      state.types = action.payload;
    },
  },
});

export const { loading, loadingCreate, setModal, addTypes } = store.actions;
export default store.reducer;
