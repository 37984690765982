import React from 'react';
import { Bar } from 'react-chartjs-2';
import { months } from 'utils/months';

import { Container } from './styles';

const defaultNumbers = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

interface IBarChart {
  contractNew?: number[];
  contractReoponing?: number[];
  contractTransfer?: number[];
  contractClosing?: number[];
}

export const BarChartComponent: React.FC<IBarChart> = ({
  contractNew = defaultNumbers,
  contractReoponing = defaultNumbers,
  contractTransfer = defaultNumbers,
  contractClosing = defaultNumbers,
}) => {
  const labels = months({ count: 12, year: '2020' });
  const state = {
    labels,
    datasets: [
      {
        label: 'Contratos (Novos)',
        data: contractNew,
        backgroundColor: 'rgba(126, 185, 91, 0.4)',
        borderColor: 'rgba(126, 185, 91, 0.6)',
        borderWidth: 1,
      },
      {
        label: 'Contratos (Reabertura)',
        data: contractReoponing,
        backgroundColor: 'rgba(176, 168, 31, 0.4)',
        borderColor: 'rgba(176, 168, 31, 0.6)',
        borderWidth: 1,
      },
      {
        label: 'Contratos (Transferência)',
        data: contractTransfer,
        backgroundColor: 'rgba(37, 109, 190, 0.4)',
        borderColor: 'rgba(37, 109, 190, 0.6)',
        borderWidth: 1,
      },
      {
        label: 'Contratos (Encerrados)',
        data: contractClosing,
        backgroundColor: 'rgba(228, 55, 102, 0.4)',
        borderColor: 'rgba(228, 55, 102, 0.6)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container>
      <Bar
        data={state}
        options={{
          responsive: true,
          animation: {
            duration: 800, // general animation time
            easing: 'easeInQuad',
          },
          maintainAspectRatio: false,
        }}
      />
    </Container>
  );
};
