import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { MdModeEdit, MdControlPoint } from 'react-icons/md';

import { useDispatch, useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material/Select';
import { queryParams, queryURI } from 'queryparamsuri';

import { fetchCountries, countriesState } from 'store/countries/action';

import { fetchAddressTypes, addressesState } from 'store/addresses/action';

import {
  fetchFranchises,
  fetchFranchiseStatus,
  fetchFranchiseTypes,
  franchisesState,
  openModalCreate,
} from 'store/franchises/action';

import { slugify } from 'utils/slug';
import { convertEmail } from 'utils/convertEmail';

import { ContentComponent } from 'components/Content';
import { LayoutComponent } from 'components/Layout';
import { BreadCrumbsComponent } from 'components/Breadcrumbs';
import { ButtonComponent } from 'components/Button';
import { PaginationComponent } from 'components/Pagination';
import { SearchComponent } from 'components/Layout/Search';
import { ModalComponent } from 'components/Modal';
import { IHandleSearch, Popover } from 'components/Popover';

import { DataFranchisesForm } from './EditOrCreate/include/dataFranchisesForm';

import { Status } from './styles';

const Franchises: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: countries } = useSelector(countriesState);
  const {
    data: franchises,
    types: franchiseTypes,
    paginate,
    loading,
    loadingPatch,
    status: franchiseStatuses,
    modalCreate,
  } = useSelector(franchisesState);
  const { types: addressTypes } = useSelector(addressesState);

  const { searchURI, page, limit, query } = queryParams({
    params: ['email', 'address', 'status', 'type'],
  });

  useEffect(() => {
    dispatch(fetchFranchises({ page, limit, ...query }));
    dispatch(fetchFranchiseStatus());
    dispatch(fetchFranchiseTypes());
    dispatch(fetchCountries());
    dispatch(fetchAddressTypes());
  }, [searchURI]); // eslint-disable-line

  const handlePage = (value: any) => {
    const params = queryURI({
      name: 'page',
      value,
    });
    history.push(params);
  };

  const handleLimit = (event: SelectChangeEvent) => {
    const params = queryURI({
      name: 'limit',
      value: event.target.value,
    });
    history.push(params);
  };

  const handleSearch = (values: IHandleSearch) => {
    const params = queryURI({
      name: values.name,
      value: values.search,
    });

    history.push(params);
  };

  const arrStatus = franchiseStatuses.map(item => item.name);
  const arrTypes = franchiseTypes.map(item => item.name);

  return (
    <>
      <LayoutComponent>
        <BreadCrumbsComponent>
          <ButtonComponent onClick={() => dispatch(openModalCreate(true))}>
            <MdControlPoint /> Adicionar
          </ButtonComponent>
        </BreadCrumbsComponent>

        <SearchComponent
          limit={String(paginate.per_page)}
          handleLimit={handleLimit}
        />

        <ContentComponent filter={loading}>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <td>#</td>
                <td>
                  Email{' '}
                  <Popover
                    handleSearch={handleSearch}
                    researched={query.email}
                    name="email"
                  />
                </td>
                <td>
                  Endereço{' '}
                  <Popover
                    handleSearch={handleSearch}
                    researched={query.address}
                    name="address"
                  />
                </td>
                <td>
                  Status{' '}
                  <Popover
                    handleSearch={handleSearch}
                    researched={query.status}
                    name="status"
                    option={arrStatus}
                  />
                </td>
                <td>
                  Tipo{' '}
                  <Popover
                    handleSearch={handleSearch}
                    name="type"
                    researched={query.type}
                    option={arrTypes}
                  />
                </td>
                <td align="right">Ação</td>
              </tr>
            </thead>
            <tbody>
              {franchises.map(item => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.email}</td>
                  <td>{item.address}</td>
                  <td width="140">
                    <Status status={slugify(item.franchiseStatus.name)}>
                      {item.franchiseStatus.name}
                    </Status>
                  </td>
                  <td>{item.franchiseType.name}</td>
                  <td align="right">
                    <ButtonComponent
                      size="small"
                      paddingVertical="small"
                      onClick={() =>
                        history.push(
                          `/franchises/edit/${convertEmail(item.email)}`,
                        )
                      }
                    >
                      <MdModeEdit /> Editar
                    </ButtonComponent>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ContentComponent>

        <PaginationComponent
          paginate={paginate}
          defaultPage={Number(query.page)}
          handlePage={handlePage}
        />
      </LayoutComponent>

      <ModalComponent
        title="Adicionar um novo Franqueado"
        content={
          <DataFranchisesForm
            statusSelect={franchiseStatuses}
            loading={loadingPatch}
            franchiseTypes={franchiseTypes}
            addressTypes={addressTypes}
            countries={countries}
            type="create"
          />
        }
        width="960px"
        open={modalCreate}
      />
    </>
  );
};

export default Franchises;
