import styled from '@emotion/styled';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 9;
  justify-content: center;
  align-items: center;

  .login {
    width: 100%;
    max-width: 340px;
    padding: 12px;
    background: #fff;
    text-align: center;
    border-radius: 4px;

    img {
      width: 90px;
    }

    button {
      width: 100%;
    }

    div + div {
      margin-top: 8px;
    }
    button {
      margin-top: 12px;
    }
  }
`;
