import * as Yup from 'yup';

interface IFormik {
  formik: any;
}

interface IType {
  type: 'edit' | 'create';
}

export const validationFranchise = (type: IType) => {
  let othersValidations = {};

  if (type.type === 'create') {
    othersValidations = {
      address: Yup.object().shape({
        address: Yup.string().required('Ops! Preencha o endereço.'),
        city: Yup.string().required('Ops! Preencha a cidade.'),
        neighborhood: Yup.string().required('Ops! Preencha o bairro.'),
        state: Yup.string().required('Ops! Preencha o estado.'),
      }),
    };
  }

  const validation = Yup.object().shape({
    franchiseStatusId: Yup.string().required('Ops! Selecione o status.'),
    franchiseTypeId: Yup.string().required('Ops! Selecione um tipo.'),
    avant_id: Yup.number().required('Ops! Adicione o id do avant.'),
    email: Yup.string().required('Ops! Preencha o seu email.'),
    phone: Yup.string().required('Ops! Preencha o telefone.'),
    foccoCode: Yup.string().required('Ops! Preencha o foccoCode.'),
    ...othersValidations,
  });

  return validation;
};

export const validationVerify = ({ formik }: IFormik) => {
  const verifyFranchiseStatusId = !!(
    formik.touched?.franchiseStatusId && formik.errors?.franchiseStatusId
  );
  const verifyFranchiseTypeId = !!(
    formik.touched?.franchiseTypeId && formik.errors?.franchiseTypeId
  );
  const verifyFranchiseAddress = !!(
    formik.touched?.address?.address && formik.errors?.address?.address
  );
  const verifyFranchiseAddressCity = !!(
    formik.touched?.address?.city && formik.errors?.address?.city
  );
  const verifyFranchiseAddressNeighborhood = !!(
    formik.touched?.address?.neighborhood &&
    formik.errors?.address?.neighborhood
  );
  const verifyFranchiseAddressCountryId = !!(
    formik.touched?.address?.countryId && formik.errors?.address?.countryId
  );
  const verifyFranchiseEmail = !!(
    formik.touched?.email && formik.errors?.email
  );
  const verifyFranchiseAddressState = !!(
    formik.touched?.address?.state && formik.errors?.address?.state
  );
  const verifyFranchisePhone = !!(
    formik.touched?.phone && formik.errors?.phone
  );
  const verifyFranchiseFoccoCode = !!(
    formik.touched?.foccoCode && formik.errors?.foccoCode
  );
  const verifyFranchiseAvantId = !!(
    formik.touched?.avant_id && formik.errors?.avant_id
  );

  return {
    verifyFranchiseStatusId,
    verifyFranchiseTypeId,
    verifyFranchiseAddress,
    verifyFranchiseAddressCity,
    verifyFranchiseAddressNeighborhood,
    verifyFranchiseAddressCountryId,
    verifyFranchiseEmail,
    verifyFranchisePhone,
    verifyFranchiseFoccoCode,
    verifyFranchiseAddressState,
    verifyFranchiseAvantId,
  };
};
