import React from 'react';

import { Zoom } from 'react-awesome-reveal';

import { LoadingPure } from 'components/Loading';

import { Container } from './styles';
import { ButtonComponent } from 'components/Button';

interface IModal {
  title: string;
  content?: any;
  cancel?: any;
  submit?: any;
  width?: string;
  open: boolean;
  loading?: boolean;
}

export const ModalComponent: React.FC<IModal> = ({
  title,
  content,
  cancel,
  submit,
  width = '600px',
  open,
  loading = false,
}) => {
  return (
    <Container open={open} width={width} data-testid="modal">
      <div className="modal">
        <Zoom style={{ width: '100%' }} duration={600}>
          <div className="modal-content">
            {loading ? (
              <LoadingPure />
            ) : (
              <>
                <div className="title">{title}</div>
                {content && <div className="content">{content}</div>}
                {submit && cancel && (
                  <div className="footer">
                    <ButtonComponent type="button" color="red" onClick={cancel}>
                      Cancelar
                    </ButtonComponent>
                    <ButtonComponent type="button" onClick={submit}>
                      Sim
                    </ButtonComponent>
                  </div>
                )}
              </>
            )}
          </div>
        </Zoom>
      </div>
    </Container>
  );
};
