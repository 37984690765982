import styled from '@emotion/styled';

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 14px;
  margin-top: 10px;
  border-top: 1px dotted #ccc;
  padding-top: 20px;

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 8px;
    padding: 24px;
    border: none;
    font-size: 24px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    transition: background-color 0.2s;

    p {
      font-size: 14px;
    }

    &:hover {
      background: #364c29;
      color: #fff;
    }
  }
`;
