import { toast } from 'react-toastify';

import { AppDispatch, AppThunk, RootState } from 'store';
import { loading, addApiIntegration } from '.';
import { refreshToken } from 'services/refreshToken';
import { IApiIntegration } from './type';

export function showSettingApiIntegration(): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const { data } = await clientApi.get('settings/api_integration');

      const apiIntegration = JSON.parse(data.json_data);

      dispatch(addApiIntegration(apiIntegration));
    } catch (err: any) {
      if (err.response.data?.message) {
        toast.warning(err.response.data.message);
      }
    } finally {
      dispatch(loading(false));
    }
  };
}

export function patchSettingApiIntegration(data: IApiIntegration): AppThunk {
  return async function () {
    try {
      const { clientApi } = await refreshToken();
      await clientApi.patch('settings/api_integration', data);

      toast.success('Atualizado com sucesso.');
    } catch (err: any) {
      if (err.response.data?.message) {
        toast.warning(err.response.data.message);
      }
    }
  };
}

export const settingState = (state: RootState) => state.settings;
