import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import rolesReducer from './roles';
import franchisesReducer from './franchises';
import contractsReducer from './contracts';
import dashboardReducer from './dashboard';
import contriesReducer from './countries';
import openingHoursReducer from './openingHours';
import bankAccountsReducer from './bankAccounts';
import gatewayReducer from './gateway';
import ownersReducer from './owners';
import publicReducer from './public';
import statesReduce from './states';
import addressesReducer from './addresses';
import usersReducer from './users';
import settingReducer from './settings';

const store = configureStore({
  reducer: {
    roles: rolesReducer,
    franchises: franchisesReducer,
    contracts: contractsReducer,
    dashboard: dashboardReducer,
    countries: contriesReducer,
    openingHours: openingHoursReducer,
    bankAccounts: bankAccountsReducer,
    gateway: gatewayReducer,
    owners: ownersReducer,
    public: publicReducer,
    states: statesReduce,
    addresses: addressesReducer,
    users: usersReducer,
    settings: settingReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
