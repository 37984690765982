import styled from '@emotion/styled';

export const BtnGrid = styled.div`
  display: flex;
  justify-content: right;

  button + button {
    margin-left: 6px;
  }
`;
