import { toast } from 'react-toastify';
import { History } from 'history';
import { AppDispatch, AppThunk, RootState } from 'store';
import {
  addRoles,
  addRole,
  addRoutes,
  addRolesTools,
  loading,
  loadingSec,
} from '.';
import { Errors } from 'utils/errors';
import { IUpdateRole, ICreateRole } from './type';
import { refreshToken } from 'services/refreshToken';

export function fetchRolesTools(): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.get('roles/tools');

      dispatch(addRolesTools(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function fetchRoles(): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.get('roles');

      dispatch(addRoles(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function fetchRolesRoutes(): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loadingSec(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.get('roles/routes');

      dispatch(addRoutes(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingSec(false));
    }
  };
}

export function showRole(id: number): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.get(`roles/${id}`);

      dispatch(addRole(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function updateRole(id: number, data: IUpdateRole): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loadingSec(true));
      const { clientApi } = await refreshToken();
      await clientApi.patch(`roles/${id}`, data);

      toast.success('Atualizado com sucesso.');
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingSec(false));
    }
  };
}

export function createRole(data: ICreateRole, history: History): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loadingSec(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.post(`roles`, data);

      toast.success('Criado com sucesso.');
      history.push(`/permissions/edit/${response.data.id}`);
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingSec(false));
    }
  };
}

export const rolesState = (state: RootState) => state.roles;
