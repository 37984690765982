import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { usersState, showUser } from 'store/users/action';
import { fetchRoles, rolesState } from 'store/roles/action';

import { LayoutComponent } from 'components/Layout';
import { BreadCrumbsComponent } from 'components/Breadcrumbs';
import { ButtonComponent } from 'components/Button';
import { CreateOrEdit } from './include/createOrEdit';

const EditUser: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, show: user } = useSelector(usersState);
  const { data: roles } = useSelector(rolesState);
  const { id }: any = useParams();

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(showUser(id));
  }, [id]); //eslint-disable-line

  return (
    <LayoutComponent loading={loading}>
      <BreadCrumbsComponent title={user?.name}>
        <ButtonComponent color="red">Sair</ButtonComponent>
      </BreadCrumbsComponent>

      <CreateOrEdit type="edit" roles={roles} user={user} />
    </LayoutComponent>
  );
};

export default EditUser;
