import { createSlice } from '@reduxjs/toolkit';
import { IUser } from './type';

const store = createSlice({
  name: 'users',
  initialState: {
    data: [] as IUser[],
    show: {} as IUser,
    modal: false,
    modalDelete: false,
    loading: false,
    loadingCreate: false,
  },
  reducers: {
    addUsers: (state, action) => {
      state.data = action.payload;
    },
    addUser: (state, action) => {
      state.show = action.payload;
    },
    removeUser: (state, action) => {
      const idUser = action.payload;
      const userIndex = state.data.findIndex(item => item.id === idUser);
      state.data = state.data.slice(0, userIndex);
    },
    setModal: (state, action) => {
      state.modal = action.payload;
    },
    setModalDelete: (state, action) => {
      state.modalDelete = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
    loadingCreate: (state, action) => {
      state.loadingCreate = action.payload;
    },
  },
});

export const {
  addUsers,
  addUser,
  removeUser,
  setModal,
  setModalDelete,
  loading,
  loadingCreate,
} = store.actions;
export default store.reducer;
