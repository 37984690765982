import React from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { Container, Filter } from './styles';

interface IContent {
  title?: string;
  filter?: boolean;
  content?: any;
  row?: boolean;
}

export const ContentComponent: React.FC<IContent> = ({
  title,
  filter = false,
  content,
  row = false,
  children,
}) => {
  return (
    <Container>
      <Filter filter={filter} row={row}>
        {!title && content && (
          <div className="title">
            <div className="content">{content}</div>
          </div>
        )}
        {title && (
          <div className="title">
            <span>
              <MdKeyboardArrowRight /> {title}
            </span>
            {content && <div className="content">{content}</div>}
          </div>
        )}

        {children}
      </Filter>
    </Container>
  );
};
