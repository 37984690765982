interface IErros {
  message: string;
  statusCode: number;
}

export const Errors = (err: any): IErros => {
  const { message, statusCode } = err.response.data;

  if (Array.isArray(message)) {
    return message[0];
  }

  return {
    message,
    statusCode,
  };
};
