import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';

import { useDispatch, useSelector } from 'react-redux';
import { MdDateRange } from 'react-icons/md';

import { t } from 'ab18n';

import { LayoutComponent } from 'components/Layout';
import { GridDashboardComponent } from 'components/Dashboard/Grid';
import { BarChartComponent } from 'components/Dashboard/Chart/bar';
import { ContentComponent } from 'components/Content';
import { GridLayoutComponent } from 'components/Layout/Grid';

import { dashboardState, fetchDashboard } from 'store/dashboard/action';

import { SelectComponent } from 'components/Select';
import { yearsUtils } from 'utils/years';

import { ContentChart, ContractStatus, SearchYear } from './styles';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(dashboardState);
  const [year, setYear] = useState(format(new Date(), 'yyyy'));
  const { charts } = data;

  useEffect(() => {
    dispatch(fetchDashboard(year));
  }, [year]); //eslint-disable-line

  return (
    <LayoutComponent>
      <SearchYear>
        <div style={{ width: 240 }}>
          <SelectComponent
            name="year"
            value={year}
            data={yearsUtils({
              year: Number(format(new Date(), 'yyyy')),
              total: 8,
            }).map(item => ({
              value: item,
              name: item,
            }))}
            handleChange={(event: any) => setYear(event.target.value)}
            title="Pesquisa Ano"
          />
        </div>
      </SearchYear>
      <GridDashboardComponent
        franchisesTotal={data.franchisesTotal}
        contractsTotal={data.contractsTotal}
      />

      <ContentChart>
        <BarChartComponent
          contractNew={charts?.newcon}
          contractReoponing={charts?.reoponing}
          contractClosing={charts?.closing}
          contractTransfer={charts?.transfer}
        />
      </ContentChart>

      <GridLayoutComponent number={2}>
        <ContentComponent title="Franqueados Atualizados Recentes">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <td>#</td>
                <td>Email</td>
                <td>Proprietário</td>
                <td>Atualizado</td>
              </tr>
            </thead>
            <tbody>
              {data.franchises?.map(item => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.email}</td>
                  <td>{item.address}</td>
                  <td>
                    <MdDateRange /> {item.updatedAt}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ContentComponent>
        <ContentComponent title="Contratos Atualizado Recentes">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <td>#</td>
                <td>Cnpj</td>
                <td>Proprietário</td>
                <td>Tipo</td>
                <td>Atualização</td>
              </tr>
            </thead>
            <tbody>
              {data.contracts?.map(item => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.cnpj}</td>
                  <td>{item.owner?.name}</td>
                  <td width="46">
                    <ContractStatus type={item.contractType.description}>
                      {t(
                        `dashboard.contractType.${item.contractType.description}`,
                      )}
                    </ContractStatus>
                  </td>
                  <td>
                    <MdDateRange /> {item.updated_at}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ContentComponent>
      </GridLayoutComponent>
    </LayoutComponent>
  );
};

export default Dashboard;
