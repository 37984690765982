import { toast } from 'react-toastify';
import { refreshToken } from 'services/refreshToken';
import { AppDispatch, AppThunk, RootState } from 'store';
import { Errors } from 'utils/errors';
import { addStates, loading } from '.';

export function fetchStates(acronym_country?: string): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const states = await clientApi.get(
        `states?acronym_country=${acronym_country || ''}`,
      );

      dispatch(addStates(states.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export const statesState = (state: RootState) => state.states;
