import React from 'react';

import { AiOutlineShop, AiOutlineFileText } from 'react-icons/ai';
import { IContractsTotal } from 'store/dashboard/type';

import { Grid } from './styles';

interface IGridDashboard {
  franchisesTotal: number;
  contractsTotal?: IContractsTotal;
}

export const GridDashboardComponent: React.FC<IGridDashboard> = ({
  franchisesTotal,
  contractsTotal,
}) => {
  return (
    <Grid>
      <div className="block green">
        <div className="details">
          <AiOutlineShop size={32} />

          <div className="name">
            <small>Total de</small>
            <span>Franqueados</span>
          </div>
        </div>
        <div className="total">{franchisesTotal}</div>
      </div>
      <div className="block green_secondary">
        <div className="details">
          <AiOutlineFileText size={32} />

          <div className="name">
            <small>Novos</small>
            <span>Contratos</span>
          </div>
        </div>
        <div className="total">{contractsTotal?.new}</div>
      </div>
      <div className="block yellow">
        <div className="details">
          <AiOutlineFileText size={32} />

          <div className="name">
            <small>Reabertura</small>
            <span>Contratos</span>
          </div>
        </div>
        <div className="total">{contractsTotal?.reoponing}</div>
      </div>
      <div className="block blue">
        <div className="details">
          <AiOutlineFileText size={32} />

          <div className="name">
            <small>Transferência</small>
            <span>Contratos</span>
          </div>
        </div>
        <div className="total">{contractsTotal?.transfer}</div>
      </div>
      <div className="block red">
        <div className="details">
          <AiOutlineFileText size={32} />

          <div className="name">
            <small>Encerrados</small>
            <span>Contratos</span>
          </div>
        </div>
        <div className="total">{contractsTotal?.closing}</div>
      </div>
    </Grid>
  );
};
