export const weeksName = () => {
  const weeks = [
    { weekDay: 1, name: 'Segunda-Feira' },
    { weekDay: 2, name: 'Terça-Feira' },
    { weekDay: 3, name: 'Quarta-Feira' },
    { weekDay: 4, name: 'Quinta-Feira' },
    { weekDay: 5, name: 'Sexta-Feira' },
    { weekDay: 6, name: 'Sábado' },
    { weekDay: 0, name: 'Domingo' },
  ];

  return weeks;
};
