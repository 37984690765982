import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IFilter {
  filter: boolean;
  row?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 14px;
  margin-top: 22px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px dotted #ccc;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;

    .content {
      display: flex;

      button + button {
        margin-left: 8px;
      }

      div + div {
        margin-left: 8px;
      }
    }

    span {
      display: flex;
      align-items: center;
    }
  }
`;

export const Filter = styled.div<IFilter>`
  width: 100%;
  display: flex;

  ${props =>
    props.row
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: column;
        `}

  ${Props =>
    Props.filter &&
    css`
      filter: blur(4px);
    `}
`;
