import React from 'react';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
import { customMask } from 'utils/mask';

interface IInput extends BaseTextFieldProps {
  label: string;
  type?:
    | 'text'
    | 'time'
    | 'email'
    | 'password'
    | 'date'
    | 'datetime-local'
    | 'number';
  value?: string;
  size?: 'small' | 'medium';
  name: string;
  onChange?: any;
  helperText?: string;
  disabled?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  mask?: string;
  error?: boolean;
}

export const InputComponent: React.FC<IInput> = ({
  label,
  type = 'text',
  value,
  name,
  size = 'medium',
  onChange,
  error = false,
  helperText,
  disabled = false,
  variant = 'outlined',
  mask,
  ...other
}) => {
  let text = value;

  if (mask) {
    text = customMask(value, mask);
  }

  return (
    <TextField
      type={type}
      {...other}
      name={name}
      label={label}
      error={error}
      variant={variant}
      size={size}
      style={{ width: '100%' }}
      onChange={onChange}
      value={text}
      helperText={helperText}
      disabled={disabled}
      inputProps={{ style: { fontSize: 13 } }}
      data-testid="textField"
      InputLabelProps={{
        shrink: true,
        style: { fontSize: 13 },
      }}
    />
  );
};
