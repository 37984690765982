import { createSlice } from '@reduxjs/toolkit';
import { Iowner, IResult } from './type';

const store = createSlice({
  name: 'owners',
  initialState: {
    data: {} as IResult,
    show: {} as Iowner,
    loading: false,
  },
  reducers: {
    addShowOwner: (state, action) => {
      state.show = action.payload;
    },
    addOwners: (state, action) => {
      state.data = action.payload;
    },
    loading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { addShowOwner, addOwners, loading } = store.actions;
export default store.reducer;
