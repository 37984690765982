import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { MdSearch } from 'react-icons/md';

import { useFormik } from 'formik';

import { patchContractTransfer } from 'store/contracts/action';
import { showOwner, OwnersState } from 'store/owners/action';

import { stringNumber } from 'utils/mask';

import { GridLayoutComponent } from 'components/Layout/Grid';
import { InputComponent } from 'components/Input';
import { ButtonComponent } from 'components/Button';

import { validationVerify } from 'validations/ownerForm';

import { ContentTitle, GridOwnerOrContract, GridLoading } from '../../styles';
import { DateInputComponent } from 'components/Input/date';

interface ITransferContractModal {
  modalTransferContract: any;
  franchiseEmail: string;
  contractFiscalNumber?: string;
}

export const TransferContractModal: React.FC<ITransferContractModal> = ({
  modalTransferContract,
  franchiseEmail,
  contractFiscalNumber,
}) => {
  const dispatch = useDispatch();
  const { show: owner, loading } = useSelector(OwnersState);
  const [fiscalNumber, setFiscalNumber] = useState('');

  const formik = useFormik({
    initialValues: {
      name: owner?.name || '',
      email: owner?.email || '',
      fiscal_number: owner?.fiscal_number || '',
      phone: owner?.phone || '',
      birthdate: owner?.birthdate || '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      if (contractFiscalNumber) {
        dispatch(
          patchContractTransfer({
            ...values,
            others: {
              franchiseEmail,
              contractFiscalNumber,
            },
          }),
        );
      }
    },
  });

  const handleAddOwner = () => {
    dispatch(showOwner(stringNumber(fiscalNumber)));
  };

  const {
    verifyContractOwnerName,
    verifyContractOwnerEmail,
    verifyContractOwnerFiscalNumber,
    verifyContractOwnerPhone,
    verifyContractOwnerBirthdate,
  } = validationVerify({ formik });

  return (
    <>
      <ContentTitle>
        <p>
          <strong>Adicionar proprietário existente</strong>
        </p>
        <form>
          <InputComponent
            label="CPF"
            name="name"
            mask="999.999.999-99"
            value={fiscalNumber}
            onChange={(event: any) => setFiscalNumber(event.target.value)}
          />
          <button type="button" onClick={handleAddOwner}>
            <MdSearch size={22} />
          </button>
        </form>
      </ContentTitle>
      <ContentTitle>
        <p style={{ marginTop: 8 }}>
          <strong>Cadastrar um novo proprietário</strong>
        </p>
      </ContentTitle>
      <GridLoading loading={loading}>
        <div className="grid-loading">
          <span>carregando...</span>
        </div>
        <div className="loading-blur">
          <form onSubmit={formik.handleSubmit}>
            <GridLayoutComponent number={2}>
              <InputComponent
                label="Nome *"
                name="name"
                error={verifyContractOwnerName}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <InputComponent
                label="Email *"
                name="email"
                error={verifyContractOwnerEmail}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </GridLayoutComponent>
            <GridLayoutComponent number={3}>
              <InputComponent
                label="CPF *"
                name="fiscal_number"
                mask="999.999.999-99"
                error={verifyContractOwnerFiscalNumber}
                value={formik.values.fiscal_number}
                onChange={formik.handleChange}
              />
              <InputComponent
                label="Telefone ou Celular *"
                name="phone"
                mask={
                  formik.values.phone.length > 14
                    ? '(99) 99999-9999'
                    : '(99) 9999-9999'
                }
                error={verifyContractOwnerPhone}
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              <DateInputComponent
                label="Nascimento *"
                name="birthdate"
                value={formik.values.birthdate}
                error={verifyContractOwnerBirthdate}
                handleDateChange={(date: any) =>
                  formik.setFieldValue('birthdate', date)
                }
              />
            </GridLayoutComponent>
            <GridOwnerOrContract>
              <div className="btn-grid-button">
                <ButtonComponent
                  type="button"
                  color="red"
                  onClick={modalTransferContract}
                >
                  Cancelar
                </ButtonComponent>
                <ButtonComponent type="submit">Cadastrar</ButtonComponent>
              </div>
            </GridOwnerOrContract>
          </form>
        </div>
      </GridLoading>
    </>
  );
};
