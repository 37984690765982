import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { queryParams, queryURI } from 'queryparamsuri';

import { useDispatch, useSelector } from 'react-redux';
import { MdControlPoint, MdModeEdit, MdDelete } from 'react-icons/md';

import {
  fetchUsers,
  deleteUser,
  setModalAction,
  usersState,
  setModalDeleteAction,
} from 'store/users/action';
import { fetchRoles, rolesState } from 'store/roles/action';

import { BreadCrumbsComponent } from 'components/Breadcrumbs';
import { ContentComponent } from 'components/Content';
import { LayoutComponent } from 'components/Layout';
import { SearchComponent } from 'components/Layout/Search';
import { ButtonComponent } from 'components/Button';
import { ModalComponent } from 'components/Modal';
import { IHandleSearch, Popover } from 'components/Popover';

import { CreateOrEdit } from './include/createOrEdit';

import { BtnGrid } from './styles';
import { IUser } from 'hooks/auth';

const Users: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data: users,
    loading,
    loadingCreate,
    modal,
    modalDelete,
  } = useSelector(usersState);
  const { data: roles } = useSelector(rolesState);
  const [userModal, setUserModal] = useState<IUser | null>(null);

  const { searchURI, query } = queryParams({
    params: ['name', 'email'],
  });

  useEffect(() => {
    dispatch(fetchUsers(searchURI));
  }, [searchURI]); //eslint-disable-line

  useEffect(() => {
    dispatch(fetchRoles());
  }, []); //eslint-disable-line

  const handleDelete = () => {
    if (userModal) dispatch(deleteUser(userModal.id));
  };

  const handleSearch = (values: IHandleSearch) => {
    const params = queryURI({
      name: values.name,
      value: values.search,
    });

    history.push(params);
  };

  return (
    <>
      <LayoutComponent>
        <BreadCrumbsComponent>
          <ButtonComponent onClick={() => dispatch(setModalAction(true))}>
            <MdControlPoint /> Adicionar
          </ButtonComponent>
        </BreadCrumbsComponent>

        <SearchComponent />

        <ContentComponent filter={loading}>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <td>#</td>
                <td>
                  Nome{' '}
                  <Popover
                    handleSearch={handleSearch}
                    researched={query.name}
                    name="name"
                  />
                </td>
                <td>
                  Email{' '}
                  <Popover
                    handleSearch={handleSearch}
                    researched={query.email}
                    name="email"
                  />
                </td>
                <td>Data de Criação</td>
                <td>Ação</td>
              </tr>
            </thead>
            <tbody>
              {users.map(item => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.created_at}</td>
                  <td align="right">
                    <BtnGrid>
                      <ButtonComponent
                        size="small"
                        paddingVertical="small"
                        onClick={() => history.push(`/users/${item.id}`)}
                      >
                        <MdModeEdit /> Editar
                      </ButtonComponent>
                      <ButtonComponent
                        size="small"
                        paddingVertical="small"
                        onClick={() => {
                          setUserModal(item as IUser);
                          dispatch(setModalDeleteAction(true));
                        }}
                        color="red"
                      >
                        <MdDelete /> Excluir
                      </ButtonComponent>
                    </BtnGrid>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ContentComponent>
      </LayoutComponent>

      <ModalComponent
        title="Adicionar um novo Usuário"
        content={<CreateOrEdit type="create" roles={roles} />}
        width="960px"
        open={modal}
      />

      <ModalComponent
        title={`Deseja realmente deletar esse usuário: ${userModal?.name}`}
        width="550px"
        loading={loadingCreate}
        submit={handleDelete}
        cancel={() => {
          setUserModal(null);
          dispatch(setModalDeleteAction(false));
        }}
        open={modalDelete}
      />
    </>
  );
};

export default Users;
