import React, { useCallback, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import {
  patchFranchise,
  createFranchise,
  openModalCreate,
} from 'store/franchises/action';
import { IFranchises, IFranchiseType } from 'store/franchises/type';

import { fetchStates, statesState } from 'store/states/action';

import { ICountries } from 'store/countries/type';

import { dataFranchiseForm } from './form';

import { ContentComponent } from 'components/Content';
import { InputComponent } from 'components/Input';
import { GridLayoutComponent } from 'components/Layout/Grid';
import { SelectComponent } from 'components/Select';
import { SwitchComponent } from 'components/Switch';
import { ButtonComponent } from 'components/Button';

import { removeLineString } from 'utils/mask';
import {
  validationFranchise,
  validationVerify,
} from 'validations/franchiseForm';

import { GridButton } from '../styles';
import api from 'services/api';
import { ICep } from 'store/public/type';
import { IAddressTypes } from 'store/addresses/type';

interface IDataFranchise {
  loading?: boolean;
  statusSelect: any[];
  franchise?: IFranchises;
  franchiseTypes: IFranchiseType[];
  addressTypes?: IAddressTypes[];
  countries?: ICountries[];
  type: 'edit' | 'create';
}

export const DataFranchisesForm: React.FC<IDataFranchise> = ({
  loading = false,
  statusSelect,
  franchise,
  franchiseTypes,
  addressTypes,
  countries,
  type,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: states } = useSelector(statesState);

  useEffect(() => {
    dispatch(fetchStates('BR'));
  }, [dispatch]);

  const status = statusSelect.map(item => ({
    value: item.name,
    name: item.id,
  }));

  const types = franchiseTypes.map(item => ({
    value: item.name,
    name: String(item.id),
  }));

  const addressTypeFilter = addressTypes?.map(item => ({
    value: item.name,
    name: String(item.id),
  }));

  const formik = useFormik({
    initialValues: dataFranchiseForm(franchise, countries),
    validationSchema: validationFranchise({ type }),
    enableReinitialize: true,
    onSubmit: values => {
      const { address, ...franchiseValues } = values;
      if (type === 'create')
        dispatch(
          createFranchise({
            data: {
              ...franchiseValues,
              address,
              avant_id: Number(values.avant_id),
            },
            history,
          }),
        );
      if (type === 'edit' && franchise?.email)
        dispatch(
          patchFranchise(franchise?.email, {
            ...franchiseValues,
            avant_id: Number(franchiseValues.avant_id),
          }),
        );
    },
  });

  const handleCountry = useCallback(
    (event: any) => {
      const { value, name } = event.target;
      const country = countries?.find(item => String(item.id) === value);
      dispatch(fetchStates(country?.acronym_country));
      formik.setFieldValue(name, value);
    },
    [countries, formik, dispatch],
  );

  const handleFetchZipCode = useCallback(
    async (event: any) => {
      const { value, name } = event.target;

      const zipCode = removeLineString(value);

      formik.setFieldValue(name, value);

      if (zipCode.length === 8) {
        const { data: cep } = await api.get<ICep>(`public/cep/${zipCode}`);

        formik.setFieldValue('address.address', cep.logradouro);
        formik.setFieldValue('address.neighborhood', cep.bairro);
        formik.setFieldValue('address.city', cep.localidade);
        formik.setFieldValue('address.state', cep.uf);
      }
    },
    [formik],
  );

  const {
    verifyFranchiseStatusId,
    verifyFranchiseTypeId,
    verifyFranchiseAddress,
    verifyFranchiseAddressCity,
    verifyFranchiseAddressCountryId,
    verifyFranchiseAddressNeighborhood,
    verifyFranchiseEmail,
    verifyFranchiseFoccoCode,
    verifyFranchisePhone,
    verifyFranchiseAddressState,
    verifyFranchiseAvantId,
  } = validationVerify({ formik });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ContentComponent filter={loading}>
        <GridLayoutComponent number={3}>
          <SelectComponent
            name="franchiseStatusId"
            data={status}
            value={String(formik.values.franchiseStatusId)}
            handleChange={formik.handleChange}
            error={verifyFranchiseStatusId}
            size="medium"
            title="Status *"
          />
          <SelectComponent
            name="franchiseTypeId"
            data={types}
            value={String(formik.values.franchiseTypeId)}
            handleChange={formik.handleChange}
            error={verifyFranchiseTypeId}
            size="medium"
            title="Tipo do Franqueado *"
          />
          <InputComponent
            label="Avant ID *"
            type="number"
            name="avant_id"
            error={verifyFranchiseAvantId}
            value={formik.values.avant_id}
            onChange={formik.handleChange}
          />
        </GridLayoutComponent>
        <GridLayoutComponent number={1}>
          <div className="grid-emailcode">
            <InputComponent
              label="Email *"
              type="email"
              name="email"
              error={verifyFranchiseEmail}
              value={formik.values.email}
              onChange={formik.handleChange}
              disabled={type === 'edit' || false}
            />
            <InputComponent
              type="number"
              label="Código do Foco *"
              name="foccoCode"
              error={verifyFranchiseFoccoCode}
              value={String(formik.values.foccoCode)}
              onChange={formik.handleChange}
            />
          </div>
        </GridLayoutComponent>
        <GridLayoutComponent number={2}>
          <InputComponent
            label="Telefone *"
            name="phone"
            value={formik.values.phone}
            error={verifyFranchisePhone}
            onChange={formik.handleChange}
            mask="(99) 9999-9999"
          />
          <div>
            <InputComponent
              label="Celular"
              name="whatsappBusiness"
              value={formik.values.whatsappBusiness}
              onChange={formik.handleChange}
              mask="(99) 99999-9999"
            />
            <SwitchComponent
              name="showWhatsAppBusiness"
              label="é whatsapp"
              ckecked={formik.values.showWhatsAppBusiness}
              handleChange={(value: any) =>
                formik.setFieldValue(
                  'showWhatsAppBusiness',
                  value.target.checked,
                )
              }
            />
          </div>
          <InputComponent
            label="Facebook"
            name="facebook"
            value={formik.values.facebook}
            onChange={formik.handleChange}
          />
          <InputComponent
            label="Instagram"
            name="instagram"
            value={formik.values.instagram}
            onChange={formik.handleChange}
          />
        </GridLayoutComponent>

        {type === 'create' && (
          <>
            <GridLayoutComponent number={3}>
              <InputComponent
                label="Cep"
                name="address.zip_code"
                mask="99999-999"
                value={formik.values.address.zip_code}
                onChange={handleFetchZipCode}
              />
              <InputComponent
                label="Endereço *"
                name="address.address"
                value={formik.values.address.address}
                error={verifyFranchiseAddress}
                onChange={formik.handleChange}
              />
              <SelectComponent
                name="address.addressTypeId"
                data={addressTypeFilter || []}
                value={String(formik.values.address.addressTypeId)}
                handleChange={formik.handleChange}
                error={verifyFranchiseTypeId}
                size="medium"
                title="Localização da Loja *"
              />
            </GridLayoutComponent>
            <GridLayoutComponent number={2}>
              <InputComponent
                label="Bairro *"
                name="address.neighborhood"
                error={verifyFranchiseAddressNeighborhood}
                value={formik.values.address.neighborhood}
                onChange={formik.handleChange}
              />
              <InputComponent
                label="Cidade *"
                name="address.city"
                value={formik.values.address.city}
                error={verifyFranchiseAddressCity}
                onChange={formik.handleChange}
              />
            </GridLayoutComponent>
            <GridLayoutComponent number={2}>
              <SelectComponent
                name="address.state"
                data={states.map(item => ({
                  value: item.name,
                  name: item.acronym_state,
                }))}
                value={String(formik.values.address.state)}
                handleChange={formik.handleChange}
                size="medium"
                error={verifyFranchiseAddressState}
                title="Estado *"
              />
              <SelectComponent
                name="address.countryId"
                data={
                  countries
                    ? countries.map(item => ({
                        value: item.name,
                        name: item.id,
                      }))
                    : []
                }
                value={formik.values.address.countryId}
                handleChange={handleCountry}
                error={verifyFranchiseAddressCountryId}
                size="medium"
                title="País *"
              />
            </GridLayoutComponent>
          </>
        )}

        <GridButton type={type === 'create'}>
          {type === 'create' && (
            <ButtonComponent
              type="button"
              color="red"
              onClick={() => dispatch(openModalCreate(false))}
            >
              Cancelar
            </ButtonComponent>
          )}

          <ButtonComponent type="submit">
            {type === 'edit' ? 'Atualizar' : 'Adicionar'}
          </ButtonComponent>
        </GridButton>
      </ContentComponent>
    </form>
  );
};
