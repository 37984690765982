import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 14px;

  .search {
    display: block;

    form {
      display: flex;
      align-items: center;

      button {
        border: 0;
        background: #147dcf;
        height: 38px;
        border-radius: 4px;
        margin-left: 4px;
        padding-right: 8px;
        padding-left: 8px;
        color: #fff;
      }
    }
  }

  .per_page {
    display: block;
    width: 200px;
  }
`;
