import { toast } from 'react-toastify';
import { offset } from 'page-offset-react';

import { AppDispatch, AppThunk, RootState } from 'store';
import { loading, addShowOwner, addOwners } from '.';
import { Errors } from 'utils/errors';
import { refreshToken } from 'services/refreshToken';

export function fetchOwners(
  search: string,
  page: string,
  limit: string,
): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.get(`owners${search}`, {
        params: {
          offset: offset({ page, limit }),
          limit: Number(limit),
        },
      });

      dispatch(addOwners(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function showOwner(identifier: string): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const { data } = await clientApi.get(`owners/identifier/${identifier}`);

      dispatch(addShowOwner(data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export const OwnersState = (state: RootState) => state.owners;
