import { Axios } from 'axios';
import api from './api';
import { addCookie, removeCookie } from './cookies';

interface IRefreshTokenPromise {
  clientApi: Axios;
  tokenRes: string;
  isRefreshToken: boolean;
}

export const refreshToken = async (): Promise<IRefreshTokenPromise> => {
  const authRefreshToken = String(process.env.REACT_APP_REFRESH_TOKEN);

  const refreshTokenId = localStorage.getItem(authRefreshToken);

  let tokenRes = '';
  let isRefreshToken = !!refreshTokenId;

  if (isRefreshToken) {
    try {
      const response = await api.post('refresh-token', {
        refreshToken: refreshTokenId,
      });

      const { access_token, refreshToken } = response.data;

      tokenRes = String(access_token);

      api.defaults.headers.common.Authorization = `Bearer ${tokenRes}`;

      const resUser = await api.get('me');

      const user = resUser.data;

      addCookie({
        name: String(process.env.REACT_APP_USER_COOKIE),
        data: JSON.stringify(user),
        expireDay: 15,
      });

      localStorage.setItem(authRefreshToken, refreshToken.id);
    } catch (err: any) {
      localStorage.removeItem(authRefreshToken);
      isRefreshToken = false;
      removeCookie(String(process.env.REACT_APP_USER_COOKIE));
    }
  }

  return {
    clientApi: api,
    tokenRes,
    isRefreshToken,
  } as IRefreshTokenPromise;
};
