import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import { Container } from './styles';
import { IPaginate } from 'utils/type.paginate';

interface IPage {
  paginate: IPaginate;
  defaultPage: number;
  handlePage: any;
}

export const PaginationComponent: React.FC<IPage> = ({
  paginate,
  defaultPage,
  handlePage,
}) => {
  const handleChange = (event: any, value: any) => {
    handlePage(value);
  };

  return (
    <Container>
      <div className="paginate">
        de {paginate.from} para {paginate.to} - total de registro{' '}
        {paginate.total}
      </div>
      <Stack spacing={2}>
        <Pagination
          count={paginate.last_page}
          defaultPage={defaultPage}
          onChange={handleChange}
        />
      </Stack>
    </Container>
  );
};
