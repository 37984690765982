import React, { createContext, useCallback, useState, useContext } from 'react';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { nextPermission } from 'role-permissions-react';

import api from 'services/api';
import { addCookie, getCookie, removeCookie } from 'services/cookies';

export interface IUser {
  id: number;
  name: string;
  email: string;
  avatar: string;
  created_at: string;
  roles: any[];
}

type ISignIn = {
  tokenId: string;
};

export interface AuthState {
  token: string;
  logged: boolean;
}

export interface AuthContextData {
  loading: boolean;
  logged: boolean;
  me: IUser;
  signIn(value: ISignIn): Promise<void>;
  signOut(): void;
  handleLoadUserMe(): void;
  handleLogged(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [logged, setLogged] = useState<boolean>(() => {
    const refreshToken = localStorage.getItem(
      String(process.env.REACT_APP_REFRESH_TOKEN),
    );

    if (refreshToken) {
      return true;
    }

    return false;
  });
  const [me, setMe] = useState<IUser>({} as IUser);

  const history = useHistory();

  const signIn = useCallback(async value => {
    try {
      setLoading(true);
      const response = await api.post('auth/login', value);
      const { access_token, refreshToken } = response.data;

      localStorage.setItem(
        String(process.env.REACT_APP_REFRESH_TOKEN),
        refreshToken.id,
      );

      api.defaults.headers.common.Authorization = `Bearer ${access_token}`;

      const resUser = await api.get('me');

      addCookie({
        name: String(process.env.REACT_APP_USER_COOKIE),
        data: JSON.stringify(resUser.data),
        expireDay: 15,
      });

      setMe(resUser.data);
      setLogged(true);
    } catch (err: any) {
      const message = err.response?.data?.message;
      if (message) toast.error(message);
    } finally {
      setLoading(false);
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem(String(process.env.REACT_APP_REFRESH_TOKEN));
    removeCookie(String(process.env.REACT_APP_USER_COOKIE));
    setLogged(false);
  }, []);

  const handleLoadUserMe = useCallback(() => {
    const userCookie = getCookie(String(process.env.REACT_APP_USER_COOKIE));
    const user = JSON.parse(userCookie);

    const passed = nextPermission({
      roles: user.roles,
    });

    if (!passed) {
      history.push('/');
      toast.warning('Ops! Você não tem permissão para visualizar essa página');
    }

    setMe(user);
  }, [history]);

  const handleLogged = useCallback(() => {
    const refreshToken = localStorage.getItem(
      String(process.env.REACT_APP_REFRESH_TOKEN),
    );

    setLogged(!!refreshToken);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        logged,
        me,
        signIn,
        signOut,
        handleLoadUserMe,
        handleLogged,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(' useAuth must be used within an authProvider ');
  }
  return context;
}
export { AuthProvider, useAuth };
