import React from 'react';

import { useHistory } from 'react-router-dom';

import { MdVpnKey } from 'react-icons/md';

import { LayoutComponent } from 'components/Layout';
import { BreadCrumbsComponent } from 'components/Breadcrumbs';

import { CardContainer } from './styles';

const Setting: React.FC = () => {
  const history = useHistory();

  return (
    <LayoutComponent>
      <BreadCrumbsComponent />

      <CardContainer>
        <button
          type="button"
          className="card"
          onClick={() => history.push('/settings/api-integration')}
        >
          <MdVpnKey size={26} />
          API Integração
          <p>Aqui você pode alterar a senha de acesso da api</p>
        </button>
      </CardContainer>
    </LayoutComponent>
  );
};

export default Setting;
