import React from 'react';

import { HeaderComponent } from './Header';
import { LoadingPure } from '../Loading';
import { Navigation } from './Navigation';

import { Container, Content, Loading } from './styles';

interface ILoading {
  loading?: boolean;
}

export const LayoutComponent: React.FC<ILoading> = ({
  children,
  loading = false,
}) => {
  return (
    <>
      <HeaderComponent />

      <Container>
        <Navigation />

        <Content>
          <div className="container">{children}</div>
        </Content>
      </Container>

      {loading && (
        <Loading>
          <LoadingPure />
        </Loading>
      )}
    </>
  );
};
