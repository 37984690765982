import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { showRole, rolesState } from 'store/roles/action';

import { BreadCrumbsComponent } from 'components/Breadcrumbs';
import { LayoutComponent } from 'components/Layout';
import { ButtonComponent } from 'components/Button';
import { CreateOrEdit } from './include/CreateOrEdit';

const EditRole: React.FC = () => {
  const dispatch = useDispatch();
  const { id }: any = useParams();

  const { show: role, loadingSec } = useSelector(rolesState);

  useEffect(() => {
    dispatch(showRole(id));
  }, [id]); // eslint-disable-line

  return (
    <LayoutComponent loading={loadingSec}>
      <BreadCrumbsComponent title={role.name}>
        <ButtonComponent color="red">Sair</ButtonComponent>
      </BreadCrumbsComponent>

      <CreateOrEdit roles={role.permissions} name={role.name} type="edit" />
    </LayoutComponent>
  );
};

export default EditRole;
