import * as Yup from 'yup';

interface IFormik {
  formik: any;
}

export const validationContract = () => {
  const validation = Yup.object().shape({
    date_opening: Yup.string().required('Ops! Preencha a data de abertura.'),
    cnpj: Yup.string().required('Ops! Preencha o cnpj'),
    company_name: Yup.string().required('Ops! Preencha a razão social'),
    name: Yup.string().required('Ops! Preencha o nome do proprietário.'),
    email: Yup.string().required('Ops! Preencha o seu email do proprietário.'),
    fiscal_number: Yup.string().required(
      'Ops! Preencha o cpf do proprietário.',
    ),
    phone: Yup.string().required('Ops! Preencha o telefone do proprietário.'),
    birthdate: Yup.date().required(
      'Ops! Preencha a data de nascimento do propriétario.',
    ),
  });

  return validation;
};

export const validationVerify = ({ formik }: IFormik) => {
  const verifyContractCnpj = !!(formik.touched?.cnpj && formik.errors?.cnpj);
  const verifyContractDateOpening = !!(
    formik.touched?.date_opening && formik.errors?.date_opening
  );
  const verifyContractCompanyName = !!(
    formik.touched?.company_name && formik.errors?.company_name
  );
  const verifyContractOwnerName = !!(
    formik.touched?.name && formik.errors?.name
  );
  const verifyContractOwnerEmail = !!(
    formik.touched?.email && formik.errors?.email
  );
  const verifyContractOwnerFiscalNumber = !!(
    formik.touched?.fiscal_number && formik.errors?.fiscal_number
  );
  const verifyContractOwnerPhone = !!(
    formik.touched?.phone && formik.errors?.phone
  );
  const verifyContractOwnerBirthdate = !!(
    formik.touched?.birthdate && formik.errors?.birthdate
  );

  return {
    verifyContractCnpj,
    verifyContractDateOpening,
    verifyContractCompanyName,
    verifyContractOwnerName,
    verifyContractOwnerEmail,
    verifyContractOwnerFiscalNumber,
    verifyContractOwnerPhone,
    verifyContractOwnerBirthdate,
  };
};
