import { toast } from 'react-toastify';
import { History } from 'history';
import { AppDispatch, AppThunk, RootState } from 'store';
import {
  addUsers,
  setModal,
  setModalDelete,
  addUser,
  removeUser,
  loading,
  loadingCreate,
} from '.';
import { Errors } from 'utils/errors';
import { ICreateUser, IUpdateUser } from './type';
import { refreshToken } from 'services/refreshToken';

export function fetchUsers(search?: string): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.get(`users${search}`);

      dispatch(addUsers(response.data));
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function createUser(data: ICreateUser, history: History): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loadingCreate(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.post('users', data);

      toast.success('Usuário adicionado com sucesso.');
      dispatch(setModal(false));
      history.push(`/users/${response.data.id}`);
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingCreate(false));
    }
  };
}

export function updateUser(id: number, data: IUpdateUser): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      await clientApi.patch(`users/${id}`, data);

      toast.success('Atualizado com sucesso.');
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function showUser(id: number): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loading(true));
      const { clientApi } = await refreshToken();
      const response = await clientApi.get(`users/${id}`);

      dispatch(addUser(response.data));
    } catch (err: any) {
      const message = Errors(err);

      toast.error(message);
    } finally {
      dispatch(loading(false));
    }
  };
}

export function deleteUser(id: number): AppThunk {
  return async function (dispatch: AppDispatch) {
    try {
      dispatch(loadingCreate(true));
      const { clientApi } = await refreshToken();
      await clientApi.delete(`users/${id}`);

      dispatch(removeUser(id));
      dispatch(setModalDelete(false));
      toast.success('Usuário deletado com sucesso.');
    } catch (err: any) {
      const error = Errors(err);

      if (error.statusCode === 403) {
        return;
      }

      toast.error(error.message);
    } finally {
      dispatch(loadingCreate(false));
    }
  };
}

export function setModalAction(event: boolean): AppThunk {
  return function (dispatch: AppDispatch) {
    dispatch(setModal(event));
  };
}

export function setModalDeleteAction(event: boolean): AppThunk {
  return function (dispatch: AppDispatch) {
    dispatch(setModalDelete(event));
  };
}

export const usersState = (state: RootState) => state.users;
