import React from 'react';

import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import moment from 'moment';

import * as ab18n from 'ab18n';
import locales from 'i18n/locale';

import AppProvider from './hooks';
import store from 'store';

import Routes from './routes';
import GlobalStyle from './styles/global';

ab18n.onLocaleChange((data: any) => moment.locale(data.locale));
ab18n.config(locales);
ab18n.set('pt-BR');

const App: React.FC = () => {
  return (
    <>
      <Router>
        <AppProvider>
          <Provider store={store}>
            <Routes />
          </Provider>
        </AppProvider>
      </Router>

      <GlobalStyle />
      <ToastContainer />
    </>
  );
};

export default App;
